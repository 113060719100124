import React, { useState } from 'react';

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq-wrapper">
      <div className="container">
        <h2 className="list-heading">Frequently Asked Questions</h2>
        <div className="section-content">
          {faqData.map((item, index) => (
            <div
              key={index}
              className={`faq-item ${openIndex === index ? 'active' : ''}`}
              onClick={() => toggleAnswer(index)}
            >
              <div className="faq-question">
                <h3 className="question-content">{item.question}</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#951e3a"
                  width="12px"
                  height="12px"
                  viewBox="0 0 14 8"
                >
                  <path
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.4"
                    d="M1 1l6 6 6-6"
                  ></path>
                </svg>
              </div>
              <p
                className="faq-answer"
                style={{
                  height: openIndex === index ? 'auto' : '0',
                  color: openIndex === index ? '#951e3a' : '#333',
                  fontWeight: openIndex === index ? 'bold' : 'normal'
                }}
              >
                {item.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const faqData = [
  {
    question: '1. Which is the best online certification university in India?',
    answer:
      'Maharishi University Online (MUIT Online) is one of the best university online courses that provides job assistance. MUIT Online is located in Noida.',
  },
  {
    question: '2. Which courses are provided by Maharishi University Online (MUIT Online)?',
    answer:
      'Maharishi University Online (MUIT Online) provides Best Online Courses like Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification.',
  },
  {
    question: '3. What is the placement ratio of MUIT Online University Courses?',
    answer:
      'Maharishi University Online (MUIT Online) provides the best online courses with 100% job assistance and paid internship.',
  },
  {
    question: '4. Is it an offline course available at Maharishi University Online?',
    answer:
      'Maharishi University Online (MUIT Online) provides fully online courses with certifications.',
  },
  {
    question: '5. What is the helpline number of Maharishi University Online?',
    answer:
      "Maharishi University's Online helpline Number is +91-9599071023.",
  },
  {
    question: '6. What is the helpline number of MUIT Online?',
    answer:
      "Maharishi University's Online helpline Number is +91-9599071023.",
  },
  {
    question: '7. What is the email of Maharishi University Online (MUIT Online)?',
    answer: (
      <>
        Maharishi University Online Emails Id:- help@muitonline.com, hr@muitonline.com
      </>
    )
  },
  {
    question: '8. What are the contact details of Maharishi University Online (MUIT Online)?',
    answer: (
      <>
        Maharishi University Online (MUIT Online) Contact Details
        <br />
        Phone Number - +91-9599071023
        <br />
        Whatsapp Number - 91-9599071023
        <br />
        E-mail: help@muitonline.com
        <br />
        E-mail: hr@muitonline.com
      </>
    ),
  },
  {
    question: '9. How does MUIT Online University differ from other universities?',
    answer:
      'MUIT Online University provides flexible, self-paced learning opportunities that can be accessed from anywhere in the world because it’s a provided online certification course, unlike other universities that require physical attendance.',
  },
];

export default Faq;
