import React, { useState, useRef } from "react";
import swal from "sweetalert";

function Pouup1({onClose}) {
  const [LFirstname, setFirstName] = useState("");
  const [LLastname, setLastname] = useState("");
  const [LEmail, setEmail] = useState("");
  const [LMobile, setMobileNumber] = useState("");
  const [mobileError, setMobileError] = useState(""); // State for mobile number validation error
  const [CourseId, setCourseId] = useState(""); // State to hold selected CourseId
  const selectRef = useRef(null); // useRef hook to create a reference
  const [LLeadsfrom, setLLeadsfrom] = useState("website");
  const [LAction, setLAction] = useState("pending");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(true); // Initially show the modal

  const handleMobileChange = (e) => {
    const { value } = e.target;
    setMobileNumber(value);

    // Validate the length of the mobile number
    if (value.length !== 10) {
      setMobileError("Mobile number must be exactly 10 digits long.");
    } else {
      setMobileError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onClose();
    if (mobileError) {
      swal({
        title: "Error",
        text: "Please correct the mobile number.",
        icon: "error",
      });
      return;
    }
    try {
      let res = await fetch(
        "https://mocrm.muituniversity.in/api/EduoApi/BrocAddLead",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            LFirstname: LFirstname,
            LLastname: LLastname,
            LEmail: LEmail,
            LMobile: LMobile,
            CourseId: CourseId,
            LLeadsfrom: LLeadsfrom,
            LAction: LAction,
          }),
        }
      );

      const resJson = await res.json();
      if (res.status === 200) {
        setFirstName("");
        setLastname("");
        setEmail("");
        setMobileNumber("");
        setCourseId("");
        setLLeadsfrom("website");
        setLAction("pending");
        setMessage("Thank you for Applying");
        swal({
          title: "Thank you for getting in touch!",
          text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!",
          icon: "success",
        }).then(() => {
          setShowModal(false); // Close the modal after successful submission
        });
      } else {
        setMessage("Some error occurred");
        swal({
          title: "Please fill all entries!",
          text: "Fill Entries",
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
   
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      id="myModal1"
      role="dialog"
      aria-hidden="true"
      style={{ display: showModal ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header1">
          <button type="button" className="close" onClick={onClose}>&times;</button>
            <h4 className="title-form1">Book Seat For </h4>
            <h4 className="title-form2">Free Counselling</h4>
            <p className="title-form1">
              Speak To Our Specialist -{" "}
              <img
                src="assets/images/footer-phone.gif"
                width="16"
                height="25"
                alt="phone"
              />{" "}
              +91 - 9599071023
            </p>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  required
                  value={LFirstname}
                  placeholder="Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              {/* {/ Other input fields omitted for brevity /} */}

              <div className="form-group" >
                <input
                  type="hidden"
                  
                  value={LLastname}
                  placeholder="Last Name"
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  required
                  value={LEmail}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  required
                  value={LMobile}
                  placeholder="Mobile Number"
                  onChange={handleMobileChange}
                />
                {mobileError && <p style={{ color: "red" }}>{mobileError}</p>}
              </div>
              <div className="form-group">
                <select
                  value={CourseId}
                  className="form-select"
                  name="CourseId"
                  id="CourseId"
                  ref={selectRef} // Assign ref to select element
                  onChange={(e) => setCourseId(e.target.value)} // Handles change and updates CourseId state
                  required // Indicates the field is required
                >
                  <option value="" disabled>
                    Select Programs & Certification
                  </option>
                  <option value="1">Fundamental Of 3D Animation</option>
                  <option value="4">
                    Visual Development/Concept Art Advanced
                  </option>
                  <option value="5">Motion Design for Graphic Designers</option>
                  <option value="6">Architecture Visualization</option>
                  <option value="7">Digital Marketing Advance</option>
                  <option value="8">
                    Social Media Marketing Short-Term Course
                  </option>
                  <option value="9">Digital Marketing Basic</option>
                  <option value="11">
                    Entrepreneurship Certification Course
                  </option>
                  <option value="12">Certification Course in Yoga</option>
                  <option value="13">
                    Certification Course in Diet and Nutrition
                  </option>
                  <option value="14">
                    Certification Course in Videography
                  </option>
                  <option value="15">Ai in Digital Marketing</option>
                  <option value="16">WordPress</option>
                </select>
              </div>

              <input
                type="hidden"
                value={LLeadsfrom}
                placeholder="LLeadsfrom"
                onChange={(e) => setLLeadsfrom(e.target.value)}
              />

              <input
                type="hidden"
                value={LAction}
                placeholder="LAction"
                onChange={(e) => setLAction(e.target.value)}
              />

              <div className="text-center">
                <button type="submit" className="btn-download-cr">
                  Submit
                </button>
              </div>

              <div className="message">{message ? <p>{message}</p> : null}</div>
            </form>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pouup1;
