import React, { useState, useEffect } from 'react';

const DateComponent = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mocrm.muituniversity.in/api/EducationCRM/WebBatchstart');
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        console.log('Fetched data:', data); // Debugging log
        if (data.data && data.data.length > 0) {
          const batchData = data.data[0];
          setStartDate(batchData.StartDate);
          setEndDate(batchData.EndDate);
        } else {
          throw new Error('No data found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' };
    return date.toLocaleDateString('en-GB', options);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : 'N/A';
  const formattedEndDate = endDate ? formatDate(endDate) : 'N/A';

  return (
    <div>
      <p>Start Date: {formattedStartDate}</p>
      <p>End Date: {formattedEndDate}</p>
    </div>
  );
};

export default DateComponent;
