import React from "react";
import axios from "axios";
import swal from "sweetalert";
function Form() {
  const validation = (event) => {
    event.preventDefault();
    const Fullname = event.target.Fullname.value;
    const LEmail = event.target.LEmail.value;
    const LMobile = event.target.LMobile.value;
    const data = { Fullname, LEmail, LMobile };

    if (
      event.target.Fullname.value === "" ||
      event.target.LEmail.value === "" ||
      event.target.LMobile.value === ""
    ) {
      swal({
        title: "Please fill all entries!",
        text: "Fill Entries",
        icon: "error",
      });
    } else {
      axios.post(data).then((response) => {
        console.log(response);
        swal({
          title: "Thank you for getting in touch!",
          text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon ! Have a great day!",
          icon: "success",
        });
        event.target.reset();
      });
    }
  };

  return (
    <>
      <div className="main">
        <section className="service-details-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div
                  className="contact-us-form  rounded p-3"
                  style={{ backgroundColor: "#951e3a" }}
                >
                  <h2 align="center" style={{ color: "#fff" }}>
                    Registration from
                  </h2>

                  <form onSubmit={validation}>
                    <div className="mb-3">
                      <input
                        required=""
                        type="text"
                        pattern="[a-zA-Z\s]+"
                        className="formControl"
                        autocomplete="off"
                        maxlength="100"
                        placeholder="Name.*"
                        id="Name"
                        name="Name"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        required=""
                        type="email"
                        className="form-control"
                        autocomplete="off"
                        maxlength="70"
                        placeholder="Email*"
                        id="Email"
                        name="Email"
                      />
                    </div>
                    <div className="mb-3">
                      <select
                        className="form-control"
                        id="CourseId"
                        required=""
                        name="CourseId"
                        data-val="true"
                        data-val-required="The CourseId field is required."
                      >
                        <option selected="" value="" className="form-control">
                          Select Programs &amp; Certification
                        </option>
                        <option value="Fundamental Of 3D Animation">
                          Fundamental Of 3D Animation
                        </option>
                        <option value=" Questioned Document & Handwriting Examination">
                          Questioned Document &amp; Handwriting Examination
                        </option>
                        <option value="Visual Development/Concept Art Advanced">
                          Visual Development/Concept Art Advanced
                        </option>
                        <option value="Motion design for graphic designers">
                          Motion design for graphic designers
                        </option>
                        <option value="Architecture Visualization">
                          Architecture Visualization
                        </option>
                        <option value="Digital Marketing - Advance">
                          Digital Marketing - Advance
                        </option>
                        <option value="Social Media Marketing Short-Term Course">
                          Social Media Marketing Short-Term Course
                        </option>
                        <option value="Digital Marketing - Basic">
                          Digital Marketing - Basic
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <input
                        required=""
                        type="tel"
                        autocomplete="off"
                        className="form-control widget_input"
                        pattern="([6-9]).{0,}"
                        title="9xxxxxxxxx "
                        maxlength="10"
                        minlength="10"
                        id="MobileNo"
                        placeholder="Contact Number *"
                        name="MobileNo"
                        onkeypress="return event.charCode >= 48 &amp;&amp; event.charCode <= 57"
                      />
                    </div>

                    <div className="mb-3">
                      <textarea
                        required=""
                        name="Enquiry"
                        id="Enquiry"
                        autocomplete="off"
                        className="form-control textarea_big widget_input"
                        placeholder="Type Your Enquiry Here"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="slider-btn">
                      <a
                        className="rbt-btn btn-gradient hover-icon-reverse"
                        href="#"
                      >
                        <span className="icon-reverse-wrapper">
                          <span className="btn-text">Submit</span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                        </span>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Form;
