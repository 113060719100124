import React, { useState, useEffect } from 'react'
import Fundamenoverview from './Fundamenoverview'
import { Helmet } from 'react-helmet';
const Fundamenbanner = () => {

    useEffect(() => {
        document.title = "Best Digital Marketing Courses Online | Maharishi University Online";
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute(
          "content",
          "Our Online Digital Marketing Course combines high-quality SEO (Search Engine Optimization), Social Media, and PPC training to provide professional success. Enroll today!"
        );
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (keywordsMeta) {
          keywordsMeta.setAttribute(
            "content",
            "Digital Marketing Course, Digital Marketing Course Online, digital marketing certificate, online digital marketing courses, fundamentals of digital marketing, digital marketing classes, best digital marketing courses, "
          );
        } else {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = 'Digital Marketing Course, Digital Marketing Course Online, digital marketing certificate, online digital marketing courses, fundamentals of digital marketing, digital marketing classes, best digital marketing courses, ';
          document.head.appendChild(keywordsMeta);
        }
      }, []);
    

  return (
    <>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>
    <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active"> <span className='textred'>Fundamentals of Digital Marketing</span></li>
                        </ul>
                        <h2 className="title"> <span className='textred'>Be a Leader in  Fundamentals of Digital Marketing</span></h2>
                        <p className="description">  Are you thinking about doing a Digital Marketing Course, So this course is best for you. Here you will get all core concepts of Digital marketing knowledge. In this Digital Marketing certification course, you will learn from industry experts.
</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.6</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star-half-o"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">15,475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>16,029 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/team-07.jpeg" alt="Tejasvita Goel"/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#">Pradeep Kumar Mishra</a> In <a href="#">Fundamentals of Digital Marketing</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 07/2023</li>
                            <li><i className="feather-globe"></i>English/Hindi</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Fundamenoverview/>
    </>
  )
}

export default Fundamenbanner