import React from "react";

const Homeourlearnerswork = () => {
  return (
    <>

      <div className="rbt-brand-area bg-color-secondary-alt-op rbt-section-gap">
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--10">
                  <span className="subtitle bg-primary-opacity">Companies</span>
                  <h2 className="title">Our Learners Work At</h2>
                  <p>
                    Muitonline learners work at some of the leading and
                    innovative organizations of today, solving core business
                    problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="overflow-hidden">
                <div className="scroll-animation-wrapper no-overlay mt--30">
                  <div className="scroll-animation scroll-left-right">
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/adobe.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}

                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/asus.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}

                    {/* <!-- Start  --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/download.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}

                    {/* <!-- Start  --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-3">
                        <img
                          src="assets/images/brand/google.png"
                          className="brandimg"
                        />
                      </div>
                    </div>
                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/hcl.jpg"
                          className="brandimg"
                        />
                      </div>
                    </div>
                    {/* <!-- End   --> */}

                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/hdfc.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}

                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/ibm.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}

                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/icici.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}

                    {/* <!-- Start  --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/met.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/mic.png"
                          className="brandimg"
                        />
                      </div>
                    </div>
                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/nokia.png"
                          className="brandimg"
                        />
                      </div>
                    </div>
                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/2.jpg"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/sam.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/sap.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/sbiinsurance.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End  --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/spicejet.png"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/tata.png"
                          className="brandimg"
                        />
                      </div>
                    </div>
                    {/* <!-- End   --> */}
                    {/* <!-- Start   --> */}
                    <div className="single-column-20 bg-theme-gradient-even">
                      <div className="rbt-testimonial-box style-2">
                        <img
                          src="assets/images/brand/timesofindia.jpg"
                          className="brandimg"
                        />
                      </div>
                    </div>

                    {/* <!-- End   --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homeourlearnerswork;
