import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import datasn from "../course.json"; // Static data from JSON file
// Import required modules for Swiper
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./Bullet.css";

const HomeBatchTime = () => {
  // State to store the fetched data from API
  const [batchDates, setBatchDates] = useState([]);
  const [courseData, setCourseData] = useState(datasn.coursegg); // Using datasn as fallback course data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch batch dates from the API
  useEffect(() => {
    const apiUrl = "https://mocrm.muituniversity.in/api/EduoApi/WebBatchstart";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setBatchDates(data.data); // Assuming data.data contains the batch start dates
        }
        setLoading(false); // Data fetching completed
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError("Error fetching batch data");
        setLoading(false);
      });
  }, []); // Empty dependency array ensures the effect runs only on component mount

  // Helper function to format date as '1 October'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Extract day
    const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
    return `${day} ${month}`; // Return date in '1 October' format
  };

  return (
    <div className="rbt-course-area bg-color-extra2 rbt-section-gap overflow-hidden">
      <div className="container">
        <div className="row mb--60">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle bg-secondary-opacity">
                Batch Update
              </span>
              <h2 className="title">Upcoming Batches</h2>
            </div>
          </div>
        </div>
        {/* Start Card Area */}
        <div className="row g-5">
          <div className="swiper event-activation-1 rbt-arrow-between rbt-dot-bottom-center pb--60 icon-bg-primary">
            <div className="swiper-wrapper mySwiper" loop="true">
              {loading ? (
                <p>Loading batch dates...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <Swiper
                  breakpoints={{
                    576: {
                      slidesPerView: 0,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                  }}
                  spaceBetween={10}
                  autoplay={{
                    delay: 1200,
                    disableOnInteraction: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {courseData.map((element) => (
                    <SwiperSlide key={element.namee}>
                      <div className="single-column-20 swiper-slide">
                        <div className="single-slide">
                          <div className="rbt-card variation-01 rbt-hover">
                            <div className="rbt-card-img">
                              <Link to={element.linkee}>
                                <img
                                  src={element.imgg}
                                  alt={element.namee}
                                  className="imgh"
                                />
                              </Link>
                            </div>
                            <div className="rbt-card-body">
                              <span className="designation theme-gradient">
                                {element.courseee}
                              </span>
                              <h6 className="min-h">
                                <Link to={element.linkee}>{element.namee}</Link>
                              </h6>
                              <ul className="rbt-meta">
                                <li>
                                  <b style={{ color: "#951e3a" }}>
                                    <i className="feather-calendar" />
                                    Batch starting on:
                                    {batchDates.length > 0 ? (
                                      batchDates.map((dateItem, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            marginLeft: "5px",
                                          }}
                                        >
                                          {/* Format the StartDate */}
                                          {formatDate(dateItem.StartDate)}
                                        </span>
                                      ))
                                    ) : (
                                      <span> No upcoming batches</span>
                                    )}
                                  </b>
                                </li>
                                <li>
                                  <i className="feather-clock" />
                                  Duration: {element.durati}
                                </li>
                                <li>
                                  <i className="feather-star" />
                                  Rating:
                                  <span
                                    style={{ marginLeft: "8px" }}
                                    className="rating"
                                  >
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star-half" />
                                  </span>
                                </li>
                                <li>
                                  <i className="feather-users" />
                                  Efforts: {element.effortse}
                                </li>
                              </ul>
                            </div>
                            <div className="rbt-btn-wrapper d-none d-xl-block">
                              <a
                                className="rbt-btn  btn-border-gradient radius-round btn-sm hover-transform-none"
                                target="_blank"
                                href="http://registration.muitonline.com"
                                rel="noopener noreferrer"
                              >
                                <span>Apply Now</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBatchTime;
