import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import datasn from '../course.json';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const HomeCoursePopular = () => {
  return (
   
    <div className="rbt-course-area bg-color-extra2 rbt-section-gap overflow-hidden">
    <div className="container">
      <div className="row mb--60">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <span className="subtitle bg-secondary-opacity">
              Top Popular Course
            </span>
            <h2 className="title">
            EXPLORE OUR MUITONLINE PROGRAMS
            </h2>
          </div>
        </div>
      </div>
      {/* Start Card Area */}
      <div className="row g-5">
              
                <div className="swiper event-activation-1 rbt-arrow-between rbt-dot-bottom-center pb--60 icon-bg-primary">

                    <div className="swiper-wrapper mySwiper " loop="true">
                    <Swiper
                     breakpoints={{
                      576: {
                        // width: 576,
                        slidesPerView: 0,
                      },
                      768: {
                        // width: 768,
                        slidesPerView: 4,
                      },
                    }}
        spaceBetween={10}
        // slidesPerView={4}
        centeredSlides={false}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
      { datasn.courseg.map(element => 
      <SwiperSlide>  
               <div className="single-column-20 swiper-slide">
                
               <div className="single-slide">
                 <div className="rbt-card variation-01 rbt-hover">
                   <div className="rbt-card-img">
                  <Link to={element.linkee}>
                  <img
                    src={element.imgg}
                    alt={element.namee}
                    className='imgh'
                  />
                  {/* <div className="rbt-badge-3 bg-white">
                    <span>{element.percentee}</span>
                    <span>Off</span>
                  </div> */}
                </Link>
              </div>
              <div className="rbt-card-body">
                <span className="designation theme-gradient">
                 {element.courseee}
                </span>
                <h6 className='min-h'>
                  <Link to={element.linkee}>{element.namee}</Link>
                </h6>
                <ul className="rbt-meta">
                  <li>
                    <i className="feather-clock" />
                    Duration: {element.durati}

                  </li>
                  <li>
                    <i className="feather-book" />
                    <b>Fees: {element.pricee}</b>
                  </li>
                  <li>
                    <i className="feather-star" />
                    Rating:
                    <span className="rating">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-half" />
                    </span>
                  </li>
                  <li>
                    <i className="feather-users" />
                    Efforts: {element.effortse}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </div>
          
              </SwiperSlide>
    )
}
    </Swiper>
                       
                       





                      




                    </div>

                     

                    




              
            </div>
           
          
        </div>
    </div>
    </div>
  )
}

export default HomeCoursePopular