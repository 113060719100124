import React from "react";
import { Link } from "react-router-dom";
const Homedepartment = () => {
  return (
    <>
      <div className="rbt-categories-area bg-color-white rbt-section-gapBottom rbt-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                {/* <span className="subtitle bg-primary-opacity">Departments</span> */}
                <h2 className="title">Seamless learning journey</h2>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--20">
            {/* <!-- Start Category Box Layout  --> */}
            <div className="col">
              <Link className="rbt-cat-box rbt-cat-box-1 text-center" to="AnimationCourse">
                <div className="inner">
                  <div className="icons">
                    <img
                      src="assets/images/category/1.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">Animation</h5>
                    <p>Course: 04</p>
                  </div>
                </div>
              </Link>
            </div>
            {/* <!-- End Category Box Layout  --> */}

            {/* <!-- Start Category Box Layout  --> */}
            <div className="col">
              <Link className="rbt-cat-box rbt-cat-box-1 text-center" to="MarketCourse">
                <div className="inner">
                  <div className="icons">
                    <img
                      src="assets/images/category/2.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">Marketing</h5>
                    <p>Course: 05</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link className="rbt-cat-box rbt-cat-box-1 text-center" to="EntrepreneurshipCourse">
                <div className="inner">
                  <div className="icons">
                    <img
                      src="assets/images/category/4.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">Entrepreneurship</h5>
                    <p>Course: 01</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link className="rbt-cat-box rbt-cat-box-1 text-center" to="CourseYoga">
                <div className="inner">
                  <div className="icons">
                    <img
                      src="assets/images/category/yoga_health.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">Yoga/Health</h5>
                    <p>Course: 02</p>
                  </div>
                </div>
              </Link>
            </div>

            {/* <div className="col">
              <a className="rbt-cat-box rbt-cat-box-1 text-center" href="#">
                <div className="inner">
                  <div className="icons">
                    <img
                      src="assets/images/category/5.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">Finance</h5>
                    <p>Course: 02</p>
                  </div>
                </div>
              </a>
            </div> */}

            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <a className="rbt-cat-box rbt-cat-box-1 text-center" href="#">
                <div className="inner">
                  <div className="icons">
                    <img
                      src="assets/images/category/6.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">Research</h5>
                    <p>Course: 07</p>
                  </div>
                </div>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Homedepartment;
