import React from "react";
import { Link } from "react-router-dom";

const Homeadavntage = () => {
  return (
    <>
      <div className="rbt-about-area bg-color-white rbt-section-gapTop  about-style-1">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="thumbnail-wrapper">
                <div className="thumbnail image-1">
                  <img
                    data-parallax='{"x": 0, "y": -20}'
                    src="assets/images/about/about-01.png"
                    alt="Education Images"
                  />
                </div>
                <div className="thumbnail image-2 d-none d-xl-block">
                  <img
                    data-parallax='{"x": 0, "y": 60}'
                    src="assets/images/about/about-02.png"
                    alt="Education Images"
                  />
                </div>
                <div className="thumbnail image-3 d-none d-md-block">
                  <img
                    data-parallax='{"x": 0, "y": 80}'
                    src="assets/images/about/about-03.png"
                    alt="Education Images"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner pl--50 pl_sm--0 pl_md--0">
                <div className="section-title text-start">
                  <span className="subtitle bg-coral-opacity">
                    Know Advantage
                  </span>
                  <h2 className="title">
                    THE MAHARISHI UNIVERSITY ONLINE ADVANTAGE
                  </h2>
                </div>

                <p className="description mt--30">
                  With our AI-driven 21st-century courses, you get the best of
                  both worlds- the comfort & flexibility of online education
                  with the help of top global experts who assure outcomes,
                  equivalent to an on-campus, conventional degree.
                </p>

                {/* <!-- Start Feature List  --> */}

                <div className="rbt-feature-wrapper mt--20 ">
                  <ul className="list-item">
                    <li> LIVE classes by faculty of international repute</li>
                    <li> Personalized mentorship by top industry leaders</li>
                    <li> Hand holding by student relationship managers</li>
                    <li> Extensive use of AI and ML with human intervention</li>
                    <li> Degree career services & placement assistance</li>
                    <li> Hands-on & immersive learning</li>
                    <li>
                      {" "}
                      Portfolio building with real-world industry projects
                    </li>
                    <li> Go global with community</li>
                  </ul>
                </div>

                {/* <!-- End Feature List  --> */}
                <div className="about-btn mt--40 mb--20">
                  <Link
                    className="rbt-btn btn-gradient hover-icon-reverse"
                    to="About"
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">More About Us</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homeadavntage;
