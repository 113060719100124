import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';

import Architecoverview from "./Architecoverview";

const Architecbanner = () => {
  useEffect(() => {
    document.title = "Architecture Visualization Online Course | Maharishi University Online";
    let descMeta = document.querySelector("meta[name='description']");
    descMeta.setAttribute(
      "content",
      "The Online Architecture Visualization course teaches digital modeling, rendering, and presentation techniques for architectural projects, enhancing design communication through software like SketchUp, Rhino, and V-Ray."
    );
    let keywordsMeta = document.querySelector("meta[name='keywords']");
    if (keywordsMeta) {
      keywordsMeta.setAttribute(
        "content",
        "Architecture Visualization Course, Architecture Visualization Online Course, Architectural Visualization Courses Online, Architectural Visualization Certificate,"
      );
    } else {
      keywordsMeta = document.createElement('meta');
      keywordsMeta.name = 'keywords';
      keywordsMeta.content = 'Architecture Visualization Course, Architecture Visualization Online Course, Architectural Visualization Courses Online, Architectural Visualization Certificate,';
      document.head.appendChild(keywordsMeta);
    }
  }, []);


  return (
    <>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>
      <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
          <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="content text-start">
                <ul className="page-list">
                  <li className="rbt-breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <div className="icon-right">
                      <i className="feather-chevron-right"></i>
                    </div>
                  </li>
                  <li className="rbt-breadcrumb-item active">
                    Architecture Visualization
                  </li>
                </ul>
                <h2 className="title">
                  {" "}
                  <span className="textred">Architecture Visualization</span>
                </h2>
                <p className="description">
                  {" "}
                  Architectural visualization is the process of creating digital
                  models of structures, buildings, and spaces using complex
                  modelling methods and powerful rendering computers.
                  Visualizing your work was never this easy. Learn the core
                  concepts and methods for presenting computer-aided designs in
                  their best lights, and make your clients happy.
                </p>

                <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">
                  <div className="feature-sin best-seller-badge">
                    <span className="rbt-badge-2">
                      <span className="image">
                        <img
                          src="assets/images/icons/card-icon-1.png"
                          alt="Best Seller Icon"
                        />
                      </span>{" "}
                      online learning platform
                    </span>
                  </div>

                  <div className="feature-sin rating">
                    <a href="#">4.2</a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star-o"></i>
                    </a>
                  </div>

                  <div className="feature-sin total-rating">
                    <a className="rbt-badge-4" href="#">
                      14,475 rating
                    </a>
                  </div>

                  <div className="feature-sin total-student">
                    <span>15,029 students</span>
                  </div>
                </div>

                <div className="rbt-author-meta mb--20">
                  <div className="rbt-avater">
                    <a href="#">
                      <img
                        src="assets/images/team/team-05.jpg"
                        alt="Tejasvita Goel"
                      />
                    </a>
                  </div>
                  <div className="rbt-author-info">
                    By <a href="#">Rajat Dua</a> In{" "}
                    <a href="#">Architecture Visualization</a>
                  </div>
                </div>

                <ul className="rbt-meta">
                  <li>
                    <i className="feather-calendar"></i>Last updated 07/2023
                  </li>
                  <li>
                    <i className="feather-globe"></i>English/Hindi
                  </li>
                  <li>
                    <i className="feather-award"></i>Certified Course
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Architecoverview />
    </>
  );
};

export default Architecbanner;
