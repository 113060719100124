import React from 'react'
import { Link } from "react-router-dom";
const Notices = () => {
  return (
    <>
    <div className="container-fluid noticetab">
        <div className="row">
            <div className="col-lg-1 noticelable">Notices</div>
            <div className="col-lg-11 noticedata">
                <div id="copyright" className="clear">
                    <marquee behavior="alternate" scrolldelay="100" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();">
                        <p className="fl_left">
                    
                             {/* || <a href="http://robotics.muitonline.com/" target="_blank"><font style={{textShadow: '0 0 1px'}}><img src="assets/images/new.gif" /> Register Now !! Maharishi botFiesta 2023 </font></a>   */}
                             
                              {/* <a href="https://bit.ly/433ugiD" target="_blank"><font style={{textShadow: '0 0 1px'}}><img src="assets/images/new.gif" /> Register for free session in Digital Marketing </font></a> || */}

                              <a href="http://registration.muitonline.com/registration-form" target="_blank"><font style={{textShadow: '0 0 1px'}}><img src="assets/images/new.gif" /> (Limited Time Offer)  40% off on Every Courses </font></a>  
                               
                              || <a href ="https://apply.muitonline.com/wordpress-course" target="_blank"><font style={{textShadow: '0 0 1px'}}><img src="assets/images/new.gif" /> Wordpress </font></a> 

                             || <Link to ="maharishi-diet-and-nutrition"><font style={{textShadow: '0 0 1px'}}><img src="assets/images/new.gif" /> Diet & Nutrition </font></Link> 
                          
                             || <Link to ="certification-in-maharishi-yoga" ><font style={{textShadow: '0 0 1px'}}><img src="assets/images/new.gif" /> Certification in Yoga </font></Link>

                            
                             </p>
                    </marquee>
                </div>
            </div>


        </div>
    </div>
    
    
    </>
  )
}

export default Notices