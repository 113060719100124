import React from "react";

const Homecontactsupport = () => {
  return (
    <>
      <section className="support-pagie ">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="support-learner spn">
                <p>FOR QUERIES, FEEDBACK OR ASSISTANCE</p>
                <h4 className="spn">Contact Muit Campus Learner Support</h4>
                <p>Best of support with us</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="support-number-india">
                <span className="spn">Phone (For Voice Call)</span>
                <p>
                  <i
                    className="fa fa-phone"
                    style={{ color: "#052b36", fontSize: "20px" }}
                    aria-hidden="true"
                  ></i>{" "}
                  <a href="tel:+919599071023" target="_blank">
                    +91-9599071023
                  </a>
                </p>
                <hr className="spn"></hr>
              </div>
              
              <div className="support-number-international">
                <span className="spn">WhatsApp (For Call &amp; Chat)</span>
                <p>
                  <i
                    className="fa fa-whatsapp"
                    style={{ color: "#25d366", fontSize: "20px" }}
                    aria-hidden="true"
                  ></i>
                  <a href="https://wa.me/919599071023" target="_blank">
                    {" "}
                    +91-9599071023
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
    </>
  );
};

export default Homecontactsupport;
