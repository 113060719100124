import React from "react";

const Hometopfaculty = () => {
  return (
    <>
      <div className="rbt-team-area bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row mb--60">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle bg-primary-opacity">
                  TOP-RANKED FACULTY FROM AROUND THE WORLD
                </span>
                <p className="title">
                  You will learn from the best at MUIT, as we impart
                  world-className education in the true sense with our leading
                  international faculty
                </p>
              </div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-7">
              {/* <!-- Start Tab Content  --> */}
              <div
                className="rbt-team-tab-content tab-content"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="team-tab1"
                  role="tabpanel"
                  aria-labelledby="team-tab1-tab"
                >
                  <div className="inner">
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-01.jpeg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                    <div className="rbt-team-details">
                      <div className="author-info">
                        <h4 className="title">Vivek Mishra</h4>
                        <span className="designation theme-gradient">
                        Animation
                        </span>
                        <span className="team-form">
                          <i className="feather-map-pin"></i>
                          <span className="location">Delhi NCR,INDIA</span>
                        </span>
                      </div>
                      <p>
                      15yrs + Experience in  VFX supervisor and technical instructo
                      </p>
                      {/* <ul className="social-icon social-default mt--20 justify-content-start">
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="feather-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com">
                            <i className="feather-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="feather-instagram"></i>
                          </a>
                        </li>
                      </ul> */}
                      {/* <ul className="rbt-information-list mt--25">
                        <li>
                          <a href="#">
                            <i className="feather-phone"></i>+91 9876543210
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@example.com">
                            <i className="feather-mail"></i>
                            teacher@muitonline.com
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>

                {/* <div
                  className="tab-pane fade"
                  id="team-tab2"
                  role="tabpanel"
                  aria-labelledby="team-tab2-tab"
                >
                  <div className="inner">
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-02.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                    <div className="rbt-team-details">
                      <div className="author-info">
                        <h4 className="title">Tejasvita Goel</h4>
                        <span className="designation theme-gradient">
                          3D Modeling and Visual Effects
                        </span>
                        <span className="team-form">
                          <i className="feather-map-pin"></i>
                          <span className="location">Delhi NCR INDIA</span>
                        </span>
                      </div>
                      <p>2yr in the industry</p>
                      <ul className="social-icon social-default mt--20 justify-content-start">
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="feather-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com">
                            <i className="feather-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="feather-instagram"></i>
                          </a>
                        </li>
                      </ul>
                      <ul className="rbt-information-list mt--25">
                        <li>
                          <a href="#">
                            <i className="feather-phone"></i>+91 9876543210
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@example.com">
                            <i className="feather-mail"></i>
                            teacher@muitonline.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div
                  className="tab-pane fade"
                  id="team-tab3"
                  role="tabpanel"
                  aria-labelledby="team-tab3-tab"
                >
                  <div className="inner">
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-03.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                    <div className="rbt-team-details">
                      <div className="author-info">
                        <h4 className="title">Ritesh Kumar Bhanu</h4>
                        <span className="designation theme-gradient">
                          Digital Marketing
                        </span>
                        <span className="team-form">
                          <i className="feather-map-pin"></i>
                          <span className="location">Delhi NCR INDIA</span>
                        </span>
                      </div>
                      <p>
                        4+ years in the industry, Social Media Marketing,
                        WordPress, SEO, SEM, Content/Video Marketing
                      </p>
                      {/* <ul className="social-icon social-default mt--20 justify-content-start">
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="feather-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com">
                            <i className="feather-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="feather-instagram"></i>
                          </a>
                        </li>
                      </ul> */}
                      {/* <ul className="rbt-information-list mt--25">
                        <li>
                          <a href="#">
                            <i className="feather-phone"></i>+91 9876543210
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@example.com">
                            <i className="feather-mail"></i>
                            teacher@muitonline.com
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="team-tab4"
                  role="tabpanel"
                  aria-labelledby="team-tab4-tab"
                >
                  <div className="inner">
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-07.jpeg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                    <div className="rbt-team-details">
                      <div className="author-info">
                        <h4 className="title">Pradeep Kumar Mishra</h4>
                        <span className="designation theme-gradient">
                          Digital Marketing
                        </span>
                        <span className="team-form">
                          <i className="feather-map-pin"></i>
                          <span className="location">Delhi NCR INDIA</span>
                        </span>
                      </div>
                      <p>8+ years of professional experience in the field of Digital Marketing.
 </p>
                      {/* <ul className="social-icon social-default mt--20 justify-content-start">
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="feather-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com">
                            <i className="feather-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="feather-instagram"></i>
                          </a>
                        </li>
                      </ul> */}
                      {/* <ul className="rbt-information-list mt--25">
                        <li>
                          <a href="#">
                            <i className="feather-phone"></i>+91 9876543210
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@example.com">
                            <i className="feather-mail"></i>
                            teacher@muitonline.com
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="team-tab5"
                  role="tabpanel"
                  aria-labelledby="team-tab5-tab"
                >
                  <div className="inner">
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-05.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                    <div className="rbt-team-details">
                      <div className="author-info">
                        <h4 className="title">Rajat Dua</h4>
                        <span className="designation theme-gradient">
                          3D Animation
                        </span>
                        <span className="team-form">
                          <i className="feather-map-pin"></i>
                          <span className="location">Delhi NCR INDIA</span>
                        </span>
                      </div>
                      <p>Assistant Professor, 14 years in the industry </p>
                      {/* <ul className="social-icon social-default mt--20 justify-content-start">
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="feather-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com">
                            <i className="feather-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="feather-instagram"></i>
                          </a>
                        </li>
                      </ul> */}
                      {/* <ul className="rbt-information-list mt--25">
                        <li>
                          <a href="#">
                            <i className="feather-phone"></i>+91 9876543210
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@example.com">
                            <i className="feather-mail"></i>
                            teacher@muitonline.com
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade"
                  id="team-tab6"
                  role="tabpanel"
                  aria-labelledby="team-tab6-tab">
                  <div className="inner">
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/rohitGupta.jpg"
                          alt="MUIT ONLINE" />
                      </div>
                    </div>
                    <div className="rbt-team-details">
                      <div className="author-info">
                        <h4 className="title">Rohit Gupta</h4>
                        <span className="designation theme-gradient">
                        Digital Marketing Expert

                        </span>
                        {/* <span className="team-form">
                          <i className="feather-map-pin"></i>
                          <span className="location">Delhi NCR INDIA</span>
                        </span> */}
                      </div>
                      <p className="textteam">
                      Rohit  is a Digital Marketing Consultant with over <b>7+ years</b> of experience, specializing in paid advertising. He has helped over 50 businesses achieve an average <b>ROI of 300%</b> through tailored strategies. As a dedicated coach, Rohit has trained over <b>1000 individuals,</b> many of whom now thrive in top MNCs like <b>Google, Accenture, and HCL,</b> or run their own successful agencies.
                      </p>
                      {/* <ul className="social-icon social-default mt--20 justify-content-start">
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="feather-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com">
                            <i className="feather-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="feather-instagram"></i>
                          </a>
                        </li>
                      </ul> */}
                      {/* <ul className="rbt-information-list mt--25">
                        <li>
                          <a href="#">
                            <i className="feather-phone"></i>+91 9876543210
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@example.com">
                            <i className="feather-mail"></i>
                            teacher@muitonline.com
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div> 

                <div className="top-circle-shape"></div>
              </div>
              {/* <!-- End Tab Content  --> */}
            </div>
            <div className="col-lg-5">
              {/* <!-- Start Tab Nav  --> */}
              <ul
                className="rbt-team-tab-thumb nav nav-tabs"
                id="myTab"
                role="tablist"
              >
                <li>
                  <a
                    className="active"
                    id="team-tab1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team-tab1"
                    role="tab"
                    aria-controls="team-tab1"
                    aria-selected="true"
                  >
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-01.jpeg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                  </a>
                </li>

                {/* <li>
                  <a
                    id="team-tab2-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team-tab2"
                    role="tab"
                    aria-controls="team-tab2"
                    aria-selected="false"
                  >
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-02.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                  </a>
                </li> */}

                <li>
                  <a
                    id="team-tab3-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team-tab3"
                    role="tab"
                    aria-controls="team-tab3"
                    aria-selected="false"
                  >
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-03.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    id="team-tab4-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team-tab4"
                    role="tab"
                    aria-controls="team-tab4"
                    aria-selected="false"
                  >
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-07.jpeg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    id="team-tab5-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team-tab5"
                    role="tab"
                    aria-controls="team-tab5"
                    aria-selected="false"
                  >
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/team-05.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                  </a>
                </li>

                 <li>
                  <a
                    id="team-tab6-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team-tab6"
                    role="tab"
                    aria-controls="team-tab6"
                    aria-selected="false"
                  >
                    <div className="rbt-team-thumbnail">
                      <div className="thumb">
                        <img
                          src="assets/images/team/rohitGupta.jpg"
                          alt="MUIT ONLINE"
                        />
                      </div>
                    </div>
                  </a>
                </li> 
              </ul>
              {/* <!-- End Tab Content  --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hometopfaculty;
