import React, { useState, useRef } from "react";
import swal from "sweetalert";
import datasn from "../../course.json";
function Fundavideoboby () {
  const [LFirstname, setFirstName] = useState("");
  const [LLastname, setLastname] = useState("");
  const [LEmail, setEmail] = useState("");
  const [LMobile, setMobileNumber] = useState("");
  const [mobileError, setMobileError] = useState(""); // State for mobile number validation error
  const [CourseId, setCourseId] = useState("1"); // State to hold selected CourseId
  const [LLeadsfrom, setLLeadsfrom] = useState("website");
  const [LAction, setLAction] = useState("pending");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(true); // Initially show the modal

  const handleMobileChange = (e) => {
    const { value } = e.target;
    setMobileNumber(value);

    // Validate the length of the mobile number
    if (value.length !== 10) {
      setMobileError('Mobile number must be exactly 10 digits long.');
    } else {
      setMobileError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobileError) {
      swal({
        title: "Error",
        text: "Please correct the mobile number.",
        icon: "error",
      });
      return;
    }
    try {
      let res = await fetch("https://mocrm.muituniversity.in/api/EduoApi/BrocAddLead", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          LFirstname: LFirstname,
          LLastname: LLastname,
          LEmail: LEmail,
          LMobile: LMobile,
          CourseId: CourseId,
          LLeadsfrom: LLeadsfrom,
          LAction: LAction,
        }),
      });

      const resJson = await res.json();
      if (res.status === 200) {
        setFirstName("");
        setLastname("");
        setEmail("");
        setMobileNumber("");
        setCourseId("1");
        setLLeadsfrom("website");
        setLAction("pending");
        setMessage("Thank you for Applying");
        swal({
          title: "Thank you for getting in touch!",
          text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!",
          icon: "success",
        }).then(() => {
          setShowModal(false); // Close the modal after successful submission
        });
      } else {
        setMessage("Some error occurred");
        swal({
          title: "Please fill all entries!",
          text: "Fill Entries",
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <>
        <div className="col-lg-4">
          <div className="course-sidebar sticky-top rbt-shadow-box course-sidebar-top rbt-gradient-border">
            <div className="inner">
              {/* <!-- Start Viedo Wrapper  --> */}

              <div className="video-content">
                <img
                  className="w-100 rbt-radius"
                  src="assets/images/course/c4.jpg"
                  alt="Video Images"
                />
              </div>

              {/* <!-- End Viedo Wrapper  --> */}

              <div className="content-item-content">
                <div className="rbt-price-wrapper d-flex flex-wrap align-items-center justify-content-between">
                  <div className="rbt-price">
                    <span className="current-price">₹29,997/-</span>
                    <span className="off-price">₹34,997/-</span>
                  </div>
                </div>

                <div className="add-to-card-button mt--15">
                  <a
                    className="rbt-btn btn-gradient icon-hover w-100 d-block text-center"
                    href="http://registration.muitonline.com/registration-form"
                    target="_blank"
                  >
                    <span className="btn-text">Enroll Now</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </a>
                </div>

                <div className="buy-now-btn mt--15">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#myModal"
                    className="rbt-btn btn-border icon-hover w-100 d-block text-center"
                  >
                    Download Brochure
                  </a>
                </div>
                {/* <span className="subtitle">
                      <i className="feather-rotate-ccw"></i> 30-Day Money-Back
                      Guarantee
                    </span> */}

                <div className="rbt-widget-details has-show-more">
                  <ul className="has-show-more-inner-content rbt-course-details-list-wrapper">
                    <li>
                      <span>Duration</span>
                      <span className="rbt-feature-value rbt-badge-5">
                        40 hours(Live Session + Recorded)
                      </span>
                    </li>
                    {/* <li>
                          <span>Efforts</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            3-6 Hours per week
                          </span>
                        </li> */}
                    <li>
                      <span>Next Batch Start On</span>
                      <span className="rbt-feature-value rbt-badge-5">
                      { datasn.dateecourse.map(element =>
    <div>{element.datesd} </div>  )}
                      </span>
                    </li>
                    <li>
                      <span>Learning Mode</span>
                      <span className="rbt-feature-value rbt-badge-5">
                        Online Interactive Learning
                      </span>
                    </li>
                    <li>
                      <span>Enrolled</span>
                      <span className="rbt-feature-value rbt-badge-5">103</span>
                    </li>
                    {/* <li>
                          <span>Lectures</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            50
                          </span>
                        </li> */}
                    <li>
                      <span>Skill Level</span>
                      <span className="rbt-feature-value rbt-badge-5">
                        Beginner to Advanced
                      </span>
                    </li>
                    <li>
                      <span>Language</span>
                      <span className="rbt-feature-value rbt-badge-5">
                        English/Hindi
                      </span>
                    </li>
                  </ul>
                  <div className="rbt-show-more-btn">Show More</div>
                </div>

                <div className="social-share-wrapper mt--30 text-center">
                  <div className="rbt-post-share d-flex align-items-center justify-content-center">
                    <ul className="social-icon social-default transparent-with-border justify-content-center">
                      <li>
                        <a href="https://www.facebook.com/muitonline">
                          <i className="feather-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/muitonline">
                          <i className="feather-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/muitonline/">
                          <i className="feather-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/muitonlineofficial">
                          <i className="feather-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <hr className="mt--20" />
                  <div className="contact-with-us text-center">
                    <p>For details about the course</p>
                    <p className="rbt-badge-2 mt--10 justify-content-center w-100">
                      <i className="feather-phone mr--5"></i> Call Us:{" "}
                      <a href="#">
                        <strong>+91 9311107230</strong>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rbt-separator-mid">
          <div class="container">
            <hr class="rbt-separator m-0" />
          </div>
        </div>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header1">
                {/* <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button> */}
                <h3 className="title-form1">Get a Free Course Brochure</h3>
              </div>
              <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  required
                  value={LFirstname}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              {/* {/ Other input fields omitted for brevity /} */}
              
              <div className="form-group">
                <input
                  type="text"
                  required
                  value={LLastname}
                  placeholder="Last Name"
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  required
                  value={LEmail}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  required
                  value={LMobile}
                  placeholder="Mobile Number"
                  onChange={handleMobileChange}
                />
                {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>}
              </div>
              <div className="form-group">
                <input
                type="hidden"
                  value={CourseId}
                  className="form-select"
                  name="CourseId"
                  id="CourseId"
                 
                  onChange={(e) => setCourseId(e.target.value)} // Handles change and updates CourseId state
                  required // Indicates the field is required
                />
              </div>

              <input
                type="hidden"
                value={LLeadsfrom}
                placeholder="LLeadsfrom"
                onChange={(e) => setLLeadsfrom(e.target.value)}
              />

              <input
                type="hidden"
                value={LAction}
                placeholder="LAction"
                onChange={(e) => setLAction(e.target.value)}
              />
              
              <div className="text-center">
                <button type="submit" className="btn-download-cr">
                  Submit
                </button>
              </div>
             
              <div className="message">{message ? <p>{message}</p> : null}</div>
            </form>
          </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-close-form"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

export default Fundavideoboby;
