import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aiindigitaloverview from './Aiindigitaloverview';

const Banner = () => {
    useEffect(() => {
        document.title = "Ai in Digital Marketing Course Online | Maharishi University Online";
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute(
          "content",
          "AI in Digital Marketing Course Online: Artificial Intelligence enhances digital marketing strategies and provides valuable customer insights for companies. So enroll now and build your career."
        );
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (keywordsMeta) {
          keywordsMeta.setAttribute(
            "content",
            "AI in Digital Marketing Course, Ai Digital Marketing Course Online, Artificial Intelligence in Digital Marketing, Ai Digital Marketing Certificate, online AI Digital Marketing Courses, Artificial Intelligence in Digital Marketing Course, Ai in Digital Marketing Classes, best Ai Digital Marketing Courses,"
          );
        } else {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = 'AI in Digital Marketing Course, Ai Digital Marketing Course Online, Artificial Intelligence in Digital Marketing, Ai Digital Marketing Certificate, online AI Digital Marketing Courses, Artificial Intelligence in Digital Marketing Course, Ai in Digital Marketing Classes, best Ai Digital Marketing Courses,';
          document.head.appendChild(keywordsMeta);
        }
      }, []);
    

  return (
    <>
    <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>

    <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active">Ai in Digital Marketing </li>
                        </ul>
                        <h2 className="title "> <span className='textred'>Ai in Digital Marketing</span></h2>
                        <p className="description"> Artificial Intelligence (AI) revolutionizes Digital Marketing by enhancing customer targeting, personalization, and automation. Through predictive analytics, AI optimizes ad placements, content delivery, and customer engagement, ensuring efficient campaigns and improved ROI. Its data-driven insights empower marketers to make informed decisions, boosting overall effectiveness in the dynamic digital landscape.</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.5</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star-half-o"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">1,475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>4,029 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/rohitGupta.jpg" alt="Rohit Gupta"/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#"> Rohit Gupta</a> In  <a href="#">AI in Digital Marketing</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 07/2023</li>
                            <li><i className="feather-globe"></i>English/Hindi</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Aiindigitaloverview/>
    </>
  )
}

export default Banner;
