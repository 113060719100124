import React from 'react'
import { Link } from "react-router-dom";
import datasn from "../course.json";
export const AllCoursepage = () => {
  return (
    <>
    <div className="grad1">
    <div className="rbt-course-area  rbt-section-gap overflow-hidden">
      <div className="container">
        <div className="row mb--60">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle bg-secondary-opacity">
                All Course
              </span>
              <h2 className="title">Choose Your Path: Selecting the Perfect Course for Your Journey</h2>
            </div>
          </div>
        </div>
        </div>
        </div>
        {/* <div className="row g-5">
          {datasn.Allincoursen.map((element) => (
            <div className="col-md-4">
              <div className="single-column-20 swiper-slide">
                <div className="single-slide">
                  <div className="rbt-card variation-01 rbt-hover">
                    <div className="rbt-card-img">
                      <Link to={element.linkee}>
                        <img src={element.imgg} alt={element.namee} />
                      </Link>
                    </div>
                    <div className="rbt-card-body">
                      <span className="designation theme-gradient">
                        {element.courseee}
                      </span>
                      <h4 className="rbt-card-title">
                        <Link to={element.linkee}>{element.namee}</Link>
                      </h4>
                      <ul className="rbt-meta">
                        <li>
                          <i className="feather-clock" />
                          Duration: {element.durati}
                        </li>
                        <li>
                          <i className="feather-book" />
                          <b>Fees: {element.pricee}</b>
                        </li>
                        <li>
                          <i className="feather-star" />
                          Rating:
                          <span className="rating">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star-half-alt" />
                          </span>
                        </li>
                        <li>
                          <i className="feather-users" />
                          Efforts: {element.effortse}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>{" "} */}
      
    <div className="rbt-section-gapTop">
    <div className="container rbt-section-gapBottom">

      
        <div className="rbt-course-grid-column list-column-half active-list-view">
            {datasn.Allincoursen.map((element) => (
            <div className="course-grid-4">
                <div className="rbt-card variation-01 rbt-hover card-list-2">

                  
                    <div className="rbt-card-img">
                        <Link to={element.linkee}>
                            <img src={element.immgg2} alt={element.namee} />
                          </Link>
                    </div>
                    <div className="rbt-card-body">
                        <span className="designation theme-gradient">
                            {element.courseee}
                          </span>
                        <h4 className="rbt-card-title"><Link to={element.linkee}>{element.namee}</Link>
                        </h4>
                        <ul className="rbt-meta">
                        <li>
                          <i className="feather-clock" />
                          Duration: {element.durati}
                        </li>
                        <li>
                          <i className="feather-book" />
                          <b>Fees: {element.pricee}</b>
                        </li>
                        <li>
                          <i className="feather-star" />
                          Rating:
                          <span className="rating">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star-half" />
                          </span>
                        </li>
                        <li>
                          <i className="feather-users" />
                          Efforts: {element.effortse}
                        </li>
                      </ul>
                            <Link className="transparent-button" to={element.linkee}>Learn
                                More<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#27374D" fill="none" fill-rule="evenodd">
                                            <path d="M10.614 0l5.629 5.629-5.63 5.629" />
                                            <path stroke-linecap="square" d="M.663 5.572h14.594" />
                                        </g>
                                    </svg></i></Link>
                        </div>
                    </div>
                </div>
          
            ))}
        </div>
    </div>

    </div>
    </div>
    </>
  )
}

export default AllCoursepage