import React from "react";

const Homeeducationfor = () => {
  return (
    <>
      {/* <!-- Start Testimonial Area   --> */}
      <div className="rbt-testimonial-area home-edu-colr rbt-section-gap overflow-hidden">
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--10">
                  <span className="subtitle  text-white">
                    EDUCATION FOR EVERYONE
                  </span>
                  <h2 className="title text-white">
                    Our Most Satisfied Students Says about us!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-animation-wrapper no-overlay mt--50 ">
          <div className="scroll-animation scroll-right-left ">
            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      I enrolled in the Digital Marketing Course with high
                      expectations, and I must say it exceeded them all. The
                      curriculum was comprehensive, covering all aspects of
                      digital marketing. The instructor demonstrated a deep
                      understanding of the subject matter, making complex
                      concepts easy to grasp.
                    </p>
                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-01.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Sonu Singh</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      I have done digital marketing course from Maharishi
                      university online. I am giving 5 start to this university
                      and also teacher Pradeep Kumar Mishra sir
                      <br></br>
                      Content: Comprehensive. Resources:Helpful. Assignments:
                      Relevant.
                      <br></br>
                      University & Teacher:Supportive and knowledgeable.
                      Overall, highly recommended for digital marketing skills.
                    </p>
                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-01.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Nazrin Hashmi</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      The Animation Course was a delightful journey into the
                      world of creativity. The instructor's expertise was
                      evident, and the course content was well-paced. I
                      appreciated the hands-on approach, allowing me to apply
                      concepts in a practical setting.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-04.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Amrita Singh</h5>
                      </div>
                     

                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd ">
              <div className="rbt-testimonial-box style-2 ">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      The My Digital Marketing Course was a game-changer for me.
                      The teacher's approach was engaging, and the hands-on
                      projects provided practical insights. The course not only
                      equipped me with the latest strategies but also boosted my
                      confidence.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-03.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Mayank</h5>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      Fantastic Animation Course! The teacher's enthusiasm for
                      
                      the craft was infectious, making every session enjoyable.
                      The curriculum was comprehensive, covering everything from
                      the basics to advanced techniques. I feel inspired and
                      ready to embark on my animation projects.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-04.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Meenal Kulkarni</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}
            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      Exceptional course! The instructor's expertise was evident
                      throughout the modules. I appreciated the blend of theory
                      and real-world examples, making the learning experience
                      both informative and enjoyable. I now feel well-prepared
                      to navigate the dynamic landscape of digital marketing.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-05.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Rajeev</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      I thoroughly enjoyed the Animation Course; it was both
                      informative and entertaining. The instructor's dedication
                      to fostering a creative learning environment was
                      commendable. The course structure allowed for gradual
                      skill development, ensuring a solid foundation in
                      animation principles.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-01.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Harsh</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      Kudos to the instructor for crafting such a
                      well-structured and valuable Digital Marketing Course. The
                      materials were top-notch, and the teacher's guidance was
                      instrumental in honing my skills. I highly recommend this
                      course to anyone looking to excel in the digital marketing
                      realm.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-07.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Disha</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      Highly recommend the Animation Course! The teacher's
                      expertise and passion for animation shone through in every
                      class. The hands-on projects were challenging yet
                      rewarding, providing a well-rounded learning experience. I
                      now have the skills and confidence to bring my animated
                      ideas to life.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-08.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Amit Singh</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}

            {/* <!-- Start Single Testimonial  --> */}
            <div className="single-column-20 bg-theme-gradient-odd">
              <div className="rbt-testimonial-box style-2">
                <div className="inner">
                  <div className="description">
                    <p className="subtitle-3">
                      Enrolling in the My Digital Marketing Course was one of
                      the best decisions I made for my career. The instructor's
                      passion for the subject matter was contagious, and the
                      course content was up-to-date with industry trends. I feel
                      equipped to tackle any digital marketing challenge
                      confidently.
                    </p>

                    <div className="clint-info-wrapper">
                      <div className="thumb">
                        <img
                          src="assets/images/testimonial/client-06.png"
                          alt="Clint Images"
                        />
                      </div>
                      <div className="client-info">
                        <h5 className="title">Saurabh Rajput</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Testimonial  --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Testimonial Area   --> */}
    </>
  );
};

export default Homeeducationfor;
