import React from 'react'

const HomeReasonsToJoin = () => {
  return (
    <>
    <div className="service-wrapper rbt-section-gap bg-color-extra2">
        <div className="container">
            <div className="row mb--60">
                <div className="col-lg-12">
                    <div className="section-title text-center">
                        <span className="subtitle bg-pink-opacity">Reasons</span>
                        <h2 className="title">The 6 Reasons To Join #MUITONLINE</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row row--15 mt_dec--30">
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="rbt-flipbox">
                                <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-1"  align="center">
                                    <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                        <div className="icon">
                                            <img src="assets/images/icons/reason-icon-1.png" alt="card-icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Learn at your Own Pace</h5>
                                        </div>
                                    </div>
                                    <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                    Learn at your Own Pace
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="rbt-flipbox">
                                <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-2"  align="center">
                                    <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                        <div className="icon">
                                            <img src="assets/images/icons/reason-icon-2.png" alt="card-icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Age No Bar</h5>
                                           
                                        </div>
                                    </div>

                                    <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                    Age No Bar
                                    </div>
                                </div>
                            </div>
                       </div>
                       
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="rbt-flipbox">
                                <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-3"  align="center">
                                    <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                        <div className="icon">
                                            <img src="assets/images/icons/reason-icon-3.png" alt="card-icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Study Whenever, Wherever</h5>
                                           
                                        </div>
                                    </div>

                                    <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                    Study Whenever, Wherever
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="rbt-flipbox">
                                <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-4"  align="center">
                                    <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                        <div className="icon">
                                            <img src="assets/images/icons/reason-icon-4.png" alt="card-icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Pursue Job and Study Simultaneously</h5>
                                         
                                        </div>
                                    </div>

                                    <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                    Pursue Job and Study Simultaneously
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="rbt-flipbox">
                                <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-5"  align="center">
                                    <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                        <div className="icon">
                                            <img src="assets/images/icons/reason-icon-5.png" alt="card-icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title text-white">Live Sessions</h5>
                                         
                                        </div>
                                    </div>

                                    <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                    Live Sessions
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="rbt-flipbox">
                                <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-6"  align="center">
                                    <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                        <div className="icon">
                                            <img src="assets/images/icons/reason-icon-6.png" alt="card-icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title text-white">Advanced Learning Management System</h5>
                                         
                                        </div>
                                    </div>

                                    <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                    Advanced Learning Management System
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
        </div>
       
   
    
    </>
  )
}

export default HomeReasonsToJoin