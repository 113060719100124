import React from "react";
import { Link } from "react-router-dom";
const Homeadvanbelow = () => {
  return (
    <>
      <div className="rbt-callto-action-area mt_dec--half">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-5">
                    <div className="inner">
                      <div className="rbt-category mb--20">
                        <a href="#">New Collection</a>
                      </div>
                      <h4 className="title mb--15">
                        Online Courses from MUIT ONLINE
                      </h4>
                      <p className="mb--15">Skill Based Scholarships</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-7">
                    <div className="video-popup-wrapper mt_lg--10 mt_md--20 mt_sm--20">
                      <img
                        className="w-100 rbt-radius"
                        src="assets/images/about/video-01.jpg"
                        alt="MUIT O"
                      />
                     
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="inner">
                      <div className="rbt-category mb--20">
                        <a href="#">Experts Teacher</a>
                      </div>
                      <h4 className="title mb--10">
                        Provide most popular courses and lets start the course
                        for
                      </h4>
                      <p className="mb--15">
                        Top instructors from around the world
                      </p>
                      <div className="read-more-btn">
                        <Link
                          className="rbt-btn rbt-switch-btn btn-gradient btn-sm" target="_blank"
                          to="http://registration.muitonline.com/registration-form"
                        >
                          <span data-text="Join Now">Join Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homeadvanbelow;
