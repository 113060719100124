import React, { useState, useEffect } from "react";
import Fundavideoboby from "./Fundavideoboby";
import Fundanioverview from "./Fundanioverview";
import { Link } from "react-router-dom";
import Popup from "../Popup";
import { Helmet } from 'react-helmet';
const Fundanibanner = () => {
    useEffect(() => {
        document.title = "3D Animation Courses | Maharishi University Online";
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute(
          "content",
          "Want to join computer and 3D animation Online courses after class 12? Maharishi University Online offers career-building 3D animation courses Online. Sharpen your skills and make your mark in the 3d animation industry."
        );
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (keywordsMeta) {
          keywordsMeta.setAttribute(
            "content",
            "3D Animation Courses, 3D animation course Online, Computer animation course Online, 3d animation certificate online, 3D animation course online, 3d design courses online"
          );
        } else {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = '3D Animation Courses, 3D animation course Online, Computer animation course Online, 3d animation certificate online, 3D animation course online, 3d design courses online';
          document.head.appendChild(keywordsMeta);
        }
      }, []);

  return (
    <>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>
      <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
          <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="content text-start">
                <ul className="page-list">
                  <li className="rbt-breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <div className="icon-right">
                      <i className="feather-chevron-right"></i>
                    </div>
                  </li>
                  <li className="rbt-breadcrumb-item active ">Animation</li>
                  <li>
                    <div className="icon-right">
                      <i className="feather-chevron-right"></i>
                    </div>
                  </li>

                  {/* <li className="rbt-breadcrumb-item"><Link to="../Brochure">Brochure</Link></li> */}
                </ul>
                <h2 className="title ">
                  <span className="textred">Fundamentals of 3D Animation</span>
                </h2>
                <p className="description">
                  Learn how to animate your custom-created character from
                  scratch. Gain the knowledge of the basics and more advanced
                  aspects of 3D creation, industry-standard 3D animation
                  software, and rule the animation industry.
                </p>

                <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">
                  <div className="feature-sin best-seller-badge">
                    <span className="rbt-badge-2">
                      <span className="image">
                        <img
                          src="assets/images/icons/card-icon-1.png"
                          alt="Best Seller Icon"
                        />
                      </span>{" "}
                      online learning platform
                    </span>
                  </div>

                  <div className="feature-sin rating">
                    <a href="#">4.3</a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-star-half-o"></i>
                    </a>
                  </div>

                  <div className="feature-sin total-rating">
                    <a className="rbt-badge-4" href="#">
                      10,475 rating
                    </a>
                  </div>

                  <div className="feature-sin total-student">
                    <span>12,402 students</span>
                  </div>
                </div>

                <div className="rbt-author-meta mb--20">
                  <div className="rbt-avater">
                    <a href="#">
                      <img
                        src="assets/images/team/team-01.jpeg"
                        alt="Vikram Singh"
                      />
                    </a>
                  </div>
                  <div className="rbt-author-info">
                    By <a href="#">Vikram Singh</a> In <a href="#">Animation</a>
                  </div>
                </div>

                <ul className="rbt-meta">
                  <li>
                    <i className="feather-calendar"></i>Last updated 09/2023
                  </li>
                  <li>
                    <i className="feather-globe"></i>English/Hindi
                  </li>
                  <li>
                    <i className="feather-award"></i>Certified Course
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Fundanioverview />
    </>
  );
};

export default Fundanibanner;
