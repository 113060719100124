import React from "react";
import { Link } from "react-router-dom";

const HomeExploreProgram = () => {
  return (
    <>
      <div className="row  rbt-section-gapTop rbt-banner-image">
        <div className="col-lg-9">
          <div className="section-title text-center">
            <h2 className="title pt-20">TOP POPULAR COURSE</h2>
          </div>
        </div>
      </div>
      <div className="rbt-category-area bg-color-white rbt-section-gapTop rbt-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              {" "}
              <div className="row g-5">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="fundamental-of-3D-animation"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/banner/fundamental-of-3D-animation.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">Fundamentals of 3D Animation</h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="advance-digital-marketing-course"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/banner/dmarkint.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">Advance in Digital Marketing</h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    to="Entrepreneurship"
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src=" assets/images/banner/Entrepreneurship.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">Entrepreneurship</h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="maharishi-diet-and-nutrition"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/course/Balanced-Diet-.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">Diet & Nutrition Course </h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="certification-in-maharishi-yoga"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/course/yoga.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">Certification in Yoga </h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="visual-development-concept-art-advanced"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/banner/visual-development-concept-art-advanced.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">
                          Visual Development/Concept Art Advanced
                        </h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="digital-marketing-course"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/course/Adavnce_digital_marketing.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">
                          Fundamentals of Digital Marketing
                        </h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <Link
                    className="rbt-cat-box rbt-cat-box-1 image-overlaping-content on-hover-content-visible"
                    to="motion-design-for-graphic-designers"
                  >
                    <div className="inner">
                      <div className="thumbnail">
                        <img
                          src="assets/images/banner/motion-design-for-graphic-designer.jpg"
                          alt="Icons Images"
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">
                          Motion Design for Graphic Designers
                        </h5>
                        <div className="read-more-btn">
                          <span className="rbt-btn-link">
                            View Course<i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="panel panel-default notice-board">
              <div className="panel-heading text-center">
                <h3 className="panel-title text-white animate-heading">Important Links</h3>
                </div>
                <div className="panel-body">
                <marquee width="100%" direction="up" scrollamount="4" height="190px">
                  <ul className="notice-list">
                    <li className="notice-item">
                      <a
                        href="http://registration.muitonline.com/registration-form"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        "Limited Time Offer" 40% off on Every Courses
                      </a>
                    </li>
                    <li className="notice-item">
                      <a
                        href="https://apply.muitonline.com/wordpress-course"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Wordpress
                      </a>
                    </li>
                    <li className="notice-item">
                      <a
                        href="maharishi-diet-and-nutrition"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Diet & Nutrition
                      </a>
                    </li>
                    <li className="notice-item">
                      <a
                        href="certification-in-maharishi-yoga"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Certification in Yoga
                      </a>
                    </li>
                    {/* Add more list items as needed */}
                  </ul>
                  </marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeExploreProgram;