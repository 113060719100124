import React from "react";

const Homecorecredentials = () => {
  return (
    <>
      <div className="rbt-categories-area corecrendent-pagie rbt-section-gapBottom rbt-section-gapTop ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                {/* <span className="subtitle bg-primary-opacity">Departments</span> */}
                <h2 className="title">Program Core Credentials</h2>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--20">
            {/* <!-- Start Category Box Layout  --> */}
            <div className="col">
              <a class="rbt-cat-box rbt-cat-box-1 text-center">
                <div class="inner">
                  <div class="icons">
                    <img
                      alt="user"
                      src="./assets/images/service/1.png"
                      width="29"
                      height="45"
                    />
                  </div>
                  <div class="content">
                    <h5 class="title">Industry Experts</h5>
                    <p>Trainer Profiles</p>
                  </div>
                </div>
              </a>
            </div>
            {/* <!-- End Category Box Layout  --> */}

            {/* <!-- Start Category Box Layout  --> */}
            <div className="col">
              <a class="rbt-cat-box rbt-cat-box-1 text-center">
                <div class="inner">
                  <div class="icons">
                    <img
                      alt="user"
                      src="./assets/images/service/2.png"
                      width="29"
                      height="45"
                    />
                  </div>
                  <div class="content">
                    <h5 class="title">10000+</h5>
                    <p>Trained Students</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col">
              <a class="rbt-cat-box rbt-cat-box-1 text-center">
                <div class="inner">
                  <div class="icons">
                    <img
                      alt="user"
                      src="./assets/images/service/3.png"
                      width="29"
                      height="45"
                    />
                  </div>
                  <div class="content">
                    <h5 class="title">100%</h5>
                    <p>Success Ratio</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col">
              <a class="rbt-cat-box rbt-cat-box-1 text-center">
                <div class="inner">
                  <div class="icons">
                    <img
                      alt="user"
                      src="./assets/images/service/4.png"
                      width="29"
                      height="45"
                    />
                  </div>
                  <div class="content">
                    <h5 class="title">For India & Abroad</h5>
                    <p>Corporate Training</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col">
              <a class="rbt-cat-box rbt-cat-box-1 text-center">
                <div class="inner">
                  <div class="icons">
                    <img
                      alt="user"
                      src="./assets/images/service/5.png"
                      width="29"
                      height="45"
                    />
                  </div>
                  <div class="content">
                    <h5 class="title">100%</h5>
                    <p>Job Assistance</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homecorecredentials;
