import React from "react";

const Privacypolicy = () => {
  return (
    <>
      {/* <!-- Start  Area  --> */}
      <div className="rbt-video-area rbt-section-gapBottom pt--50 bg-color-extra2 mt--30">
    <div className="container">
        <div className="row g-5 align-items-center">
            <div className="col-lg-12 order-2 order-lg-1">
                <div className="inner pr--50">
                    <div className="section-title text-start">
                        <span className="subtitle bg-primary-opacity">
                            Privacy Policy
                        </span>
                        <p>
                            Maharishi University Online ("we", "us", "our") operates the
                            https://muitonline.com website.
                        </p>
                        <p>
                            <b>Information Collection and Use</b>
                            <br />
                            While using our Service, we may ask you to provide personally identifiable information, including but not limited to:
                        </p>
                        • Name <br />
                        • Mobile Number
                        <br />
                        • Email Id
                        <br />
                        • Address
                        <br />
                        <br />
                        <p>
                            We may use your personal data to contact you with newsletters, marketing promotions materials and other information that may be of interest to you.
                        </p>
                        <p>
                            <b>Use of Data</b>
                        </p>
                        <p>
                            • We may use your personal data to provide information of our services.
                            <br />
                            • We may use your personal data to provide training Support.
                            <br />
                            • We may use your personal data to provide special offers, information, new courses, services, event, free workshop to you.
                            <br />
                            • We may use your personal data for any other purpose with your consent.
                            <br />
                            • We may be sharing your data with Google AdWords, Facebook Ads, Google Analytics and Google tag manager to provide you necessary information regarding new courses, batch launch notice, offers etc.
                        </p>
                        <p>
                            <b>Method of Payment</b>
                        </p>
                        <p>
                            We accept payments offline & online using Visa, MasterCard, Paytm, PhonePe, GPay, and UPI in INR currency. Payment card details are processed by third-party payment processors.
                        </p>
                        <p>
                            We will not store or collect your payment card details. That types of information are provided directly to our third-party payment processors who use of your personal information governed by their privacy policy.
                        </p>
                        <p>
                            <b>Refund Policy</b>
                        </p>
                        <p>
                            Fee once paid is not refundable under any circumstances.
                            <br />
                            No request for fee refund will be entertained.
                        </p>
                        <p>
                            <b>Terms of Use</b>
                        </p>
                        <p>
                            By accessing and using our website, you acknowledge and accept our Terms of Use and Privacy Policy. If you disagree, you are not authorized to use our site.
                        </p>
                        <p>
                            <b>Contact Us</b>
                        </p>
                        <p>
                            For any questions, please contact us at <a href="mailto:help@muitonline.com">help@muitonline.com</a>
                        </p>
                        <p>
                            <b>Updates</b>
                        </p>
                        <p>
                            We may update our Privacy Policy from time to time by posting a new version on our website.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

      {/* <!-- End  Area  --> */}
    </>
  );
};

export default Privacypolicy;
