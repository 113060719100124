import React, { useState, useEffect } from 'react'
import Enteroverview from './Enteroverview'
import { Helmet } from 'react-helmet';
const Enterbanner = () => {

    useEffect(() => {
        document.title = "Entrepreneurship Course Online | Maharishi University Online";
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute(
          "content",
          "Learn essential skills to start and grow your own business with our comprehensive Online Entrepreneurship Course. Gain practical knowledge, expert insights, and real-world experience. Enroll today!"
        );
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (keywordsMeta) {
          keywordsMeta.setAttribute(
            "content",
            "Entrepreneurship Course, Entrepreneurship Course Online, Entrepreneurship Course certificate, online Entrepreneurship Course, Fundamentals of Entrepreneurship Course, Entrepreneurship Course classes, best Entrepreneurship Course,"
          );
        } else {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = 'Entrepreneurship Course, Entrepreneurship Course Online, Entrepreneurship Course certificate, online Entrepreneurship Course, Fundamentals of Entrepreneurship Course, Entrepreneurship Course classes, best Entrepreneurship Course,';
          document.head.appendChild(keywordsMeta);
        }
      }, []);
    

  return (
    <>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>
    <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active"> Entrepreneurship</li>
                        </ul>
                        <h2 className="title"><span className='textred'>Entrepreneurship</span></h2>
                        <p className="description">Discover the secrets of successful entrepreneurship and nurture the entrepreneurial mindset essential for triumph in the business realm. Join our comprehensive certification program to kickstart your journey as an entrepreneur. Seize this opportunity to shape your future as a thriving entrepreneur.</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.5</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star-half-o"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">1000 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>1200 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/teamname.png" alt="Mr. Adit Goswami"/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#">Mr. Adit Goswami </a> In <a href="#">Entrepreneurship</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 07/2023</li>
                            <li><i className="feather-globe"></i>English/Hindi</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Enteroverview/>
    </>
  )
}

export default Enterbanner