import { Link } from "react-router-dom";
let resumeData = {
  jobs: [
    {
      expData: {
        company: "Animation",
       
        details: [
          <ul className="rbt-vertical-nav-list-wrapper">
         <li><Link to="fundamental-of-3D-animation">Fundamentals of 3D Animation</Link></li>
          <li><Link to="visual-development-concept-art-advanced">Visual Development / Concept Art
                                          Advanced</Link></li>
          <li><Link to="motion-design-for-graphic-designers"> Motion design for graphic designers</Link></li>
          <li><Link to="Architecture-visualization">Architecture Visualization</Link></li>
        </ul>
        ]
      }
    },
    {
      expData: {
        company: "Marketing",
       
        details: [
          <ul className="rbt-vertical-nav-list-wrapper">
          <li><Link to="advance-digital-marketing-course">Advanced Certification in Digital                                   Marketing</Link></li>
          <li><Link to="digital-marketing-course"> Fundamentals of Digital Marketing</Link></li>
          <li><Link to="Social-Media-Marketing">Social Media Marketing Short-Term Course</Link></li>
          <li><Link to="Ai-in-digital-Marketing">AI in Digital Marketing</Link></li>
          <li><Link to="https://apply.muitonline.com/wordpress-course" target="_blank">Wordpress</Link></li>
        </ul>
        ]
      }
    },
    {
      expData: {
       
        company: "Entrepreneurship ",
       
        details: [
          <ul className="rbt-vertical-nav-list-wrapper">
          <li><Link to="Entrepreneurship">Entrepreneurship <span class="rbt-badge-card">New</span></Link></li>
        </ul>
        ]
      }
    },
    {
      expData: {
       
        company: "Yoga/Health ",
       
        details: [
          <ul className="rbt-vertical-nav-list-wrapper">
          <li><Link to="maharishi-diet-and-nutrition">Diet & Nutrition Course </Link></li>
          <li><Link to="certification-in-maharishi-yoga">Certification in Yoga </Link></li>
        </ul>
        ]
      }
    },
    // {
    //   expData: {
    //     company: "Videography",
       
    //     details: [
    //       <ul className="rbt-vertical-nav-list-wrapper">
    //                                       <li><Link to="Videography">Videography</Link></li>
    //                                     </ul>
    //     ]
    //   }
    // },
    // {
    //   expData: {
    //     company: "Yoga",
       
    //     details: [
    //       <ul className="rbt-vertical-nav-list-wrapper">
    //                                       <li><Link to="Yoga">Yoga</Link></li>
    //                                     </ul>
    //     ]
    //   }
    // },
    // {
    //   expData: {
    //     company: "Risk Management",
       
    //     details: [
    //       <ul className="rbt-vertical-nav-list-wrapper">
    //                                       <li><Link to="Risk-Management">Risk Management</Link></li>
    //                                     </ul>
    //     ]
    //   }
    // },
    // {
    //   expData: {
    //     company: "Diet & Nutrition",
       
    //     details: [
    //       <ul className="rbt-vertical-nav-list-wrapper">
    //                                       <li><Link to="Diet&Nutrition">Diet & Nutrition</Link></li>
    //                                     </ul>
    //     ]
    //   }
    // }
  ]
};

export default resumeData;
