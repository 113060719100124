import React, { useState, useEffect } from 'react'
import Socialmediaoverview from './Socialmediaoverview'
import { Helmet } from 'react-helmet';
const Socialmediabanner = () => {

    useEffect(() => {
        document.title = "Best Social Media Marketing Courses Online | Maharishi University Online";
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute(
          "content",
          "Our Social Media Marketing online training courses provide the skills you require, from the fundamentals to advanced tips. Browse our comprehensive preference of Social Media Marketing courses to find precisely what you want."
        );
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (keywordsMeta) {
          keywordsMeta.setAttribute(
            "content",
            "Social Media Marketing Course, Social Media Marketing Course Online, Social Media Marketing certificate, online Social Media Marketing courses, fundamentals of Social Media Marketing, Social Media Marketing classes, best Social Media Marketing courses, "
          );
        } else {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = 'Social Media Marketing Course, Social Media Marketing Course Online, Social Media Marketing certificate, online Social Media Marketing courses, fundamentals of Social Media Marketing, Social Media Marketing classes, best Social Media Marketing courses, ';
          document.head.appendChild(keywordsMeta);
        }
      }, []);
    

  return (
    <>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>
     <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active"> Socialmedia</li>
                        </ul>
                        <h2 className="title"> <span className='textred'>Be a Leader in  Masters in Social Media Marketing</span></h2>
                        <p className="description"> Empower yourself with Social Media Marketing Skills. Become Social Media Marketing Expert- Learn how to do marketing online, generate potential leads, boost website traffic & increase sales revenue with better brand awareness & marketing, just like the experts do.
</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.8</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">15,475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>16,029 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/team-03.jpg" alt="Tejasvita Goel"/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#">Ritesh Kumar Bhanu</a> In <a href="#">Social Media Marketing</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 07/2023</li>
                            <li><i className="feather-globe"></i>English/Hindi</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Socialmediaoverview/>
    </>
  )
}

export default Socialmediabanner