import React from 'react'

const Homegetlatest = () => {
  return (
    <>
     <div className="rbt-newsletter-area newsletter-style-2 bg-color-primary rbt-section-gap">
            <div className="container">
                <div className="row row--15 align-items-center">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <div className="section-title text-center">
                                <span className="subtitle bg-white-opacity">Get Latest MUIT Online Update</span>
                                <h2 className="title color-white"><strong>Subscribe</strong> Our Newsletter</h2>
                            </div>
                            <form action="#" className="newsletter-form-1 mt--40">
                                <input type="email" placeholder="Enter Your E-Email"/>
                                <button type="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse">
                                    <span className="icon-reverse-wrapper">
                            <span className="btn-text">Subscribe</span>
                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                    </span>
                                </button>
                            </form>

                            <div className="row row--15 mt--50">
                                {/* <!-- Start Single Counter --> */}
                                <div className="col-lg-3 offset-lg-3 col-md-6 col-sm-6 single-counter">
                                    <div className="rbt-counterup rbt-hover-03 style-2 text-color-white">
                                        <div className="inner">
                                            <div className="content">
                                                <h3 className="counter color-white"><span className="odometer" data-count="500">500</span>
                                                </h3>
                                                <h5 className="title color-white">Successfully Trained</h5>
                                                <span className="subtitle color-white">Learners &amp; counting</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Single Counter --> */}

                                {/* <!-- Start Single Counter --> */}
                                <div className="col-lg-3 col-md-6 col-sm-6 single-counter mt_mobile--30">
                                    <div className="rbt-counterup rbt-hover-03 style-2 text-color-white">
                                        <div className="inner">
                                            <div className="content">
                                                <h3 className="counter color-white"><span className="odometer" data-count="100">100</span>
                                                </h3>
                                                <h5 className="title color-white">Certification Students</h5>
                                                <span className="subtitle color-white">Online Course</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Single Counter --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Homegetlatest