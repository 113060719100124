import React from "react";

const Becomeaninstructor = () => {
  return (
    <>
      <section
        className="course-wrap-style-1 paddings"
        style="background-repeat: no-repeat;
        background-image: url('assets/media/beani.png');
        background-attachment: fixed;
        background-position: right;"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="course-details__curriculum">
                <div className="section-heading style-1">
                  <h2 className="title muitcolor">
                    Become An Instructor
                    <br />
                    <br />
                  </h2>
                  <p>
                    Being an instructor is not a bad job because through this
                    profession you can deliver your skills to others in the most
                    efficient way so that other people can with the other
                    companies.
                  </p>
                </div>
                <div className="row featuredContainer">
                  <div className="col-xl-12 col-md-12 col-12 Basic">
                    <div className="course-box style-1">
                      <form method="post" enctype="multipart/form-data">
                        <div className="row student_enrollment_form">
                          <div className="col-md-6 pb-4">
                            <label>
                              Name{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              pattern="[a-zA-Z\s]+"
                              maxlength="100"
                              data-val="true"
                              data-val-required="Please Name"
                              id="Name"
                              placeholder="Name"
                              required="required"
                              type="text"
                              name="Name"
                              autocomplete="off"
                              value=""
                            />
                          </div>
                          <div className="col-md-6 pb-4">
                            <label>
                              Email{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              maxlength="100"
                              id="Email"
                              placeholder="Email"
                              type="Email"
                              required
                              name="Email"
                              autocomplete="off"
                              value=""
                            />
                          </div>

                          <div className="col-md-6 pb-4">
                            <label>
                              Contact Number{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <div className="row no-gutters">
                              <div className="col-md-3 select_box2">
                                <button
                                  className="form-control dropdown-toggle"
                                  id="btnMCode"
                                  onClick="addClasses(this.id);"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  +91
                                  <span className="caret"></span>
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  style="max-height: 200px; overflow: auto;"
                                >
                                  <li>
                                    <a
                                      id="India"
                                      href="javascript:void(0)"
                                      onClick="fnFatherCodeReplace( 91);"
                                    >
                                      India (+91)
                                    </a>{" "}
                                  </li>
                                </ul>
                              </div>

                              <div className="col-md-9 pb-2">
                                <input
                                  className="form-control "
                                  data-val="true"
                                  autocomplete="off"
                                  data-val-length="Mobile Number Must Be 10 Digit."
                                  data-val-length-max="10"
                                  data-val-regex="Mobile number is only numeric"
                                  pattern="([6-9]).{0,}"
                                  title="9xxxxxxxxx "
                                  maxlength="10"
                                  minlength="10"
                                  data-val-required="Please select mobile number."
                                  id="ContactNumber"
                                  placeholder="Mobile No."
                                  required
                                  type="tel"
                                  name="ContactNumber"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                  value=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 select_box3 pb-4">
                            <label>
                              Course Name{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <select
                              className="form-control"
                              id="CourseId"
                              required
                              name="CourseId"
                              data-val="true"
                              data-val-required="The CourseId field is required."
                            >
                              <option selected value="">
                                Select Course Name
                              </option>
                              <option value="1">
                                Fundamental Of 3D Animation
                              </option>
                              <option value="3">
                                Questioned Document &amp; Handwriting
                                Examination
                              </option>
                              <option value="4">
                                Visual Development/Concept Art Advanced
                              </option>
                              <option value="5">
                                Motion design for graphic designers
                              </option>
                              <option value="6">
                                Architecture Visualization
                              </option>
                              <option value="7">
                                Digital Marketing - Advance
                              </option>
                              <option value="8">
                                Social Media Marketing Short-Term Course{" "}
                              </option>
                              <option value="9">
                                Digital Marketing - Basic
                              </option>
                            </select>
                          </div>
                          <div className="col-md-6 pb-4">
                            <label>
                              Job Type{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <select
                              className="form-control"
                              data-val="true"
                              data-val-number="The field CountryId must be a number."
                              data-val-required="The CountryId field is required."
                              id="JobType"
                              required="required"
                              name="JobType"
                            >
                              <option selected value="">
                                -- Select Type --{" "}
                              </option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time </option>
                            </select>
                          </div>
                          <div className="col-md-6 pb-4">
                            <label>
                              Linkedin Profile{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              autocomplete="off"
                              data-val="true"
                              data-val-required="Please enter mother name."
                              id="LinkedinProfile"
                              placeholder="Linkedin Profile"
                              required
                              type="text"
                              name="LinkedinProfile"
                              value=""
                            />
                          </div>

                          <div className="col-md-6 pb-4">
                            <label>
                              Tell us about yourself{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <textarea
                              className="form-control"
                              autocomplete="off"
                              data-val="true"
                              data-val-required="Please enter mother name."
                              id="Tellaboutyourself"
                              placeholder="Tell us about yourself "
                              required
                              type="text"
                              name="Tellaboutyourself"
                            ></textarea>
                          </div>
                          <div className="col-md-6 pb-4">
                            <label>
                              About the Course{" "}
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <textarea
                              className="form-control"
                              autocomplete="off"
                              data-val="true"
                              data-val-required="Please enter mother name."
                              id="AbouttheCourse"
                              placeholder="About the Course"
                              required
                              type="text"
                              name="AbouttheCourse"
                            ></textarea>
                          </div>

                          <div className="col-md-6 pb-4">
                            <label>
                              Upload your latest resume (Only PDF Files)
                              <b>
                                <span style="color:red">*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              autocomplete="off"
                              data-val="true"
                              data-val-required="Please enter Resume."
                              id="Resume"
                              placeholder="Upload Resume"
                              required
                              type="file"
                              name="Resume"
                              accept="application/pdf"
                            />
                          </div>
                          <div className="col-md-12 pb-12">
                            <label>
                              <input
                                autocomplete="off"
                                data-val-required="Please Select"
                                placeholder="Please Select "
                                required
                                type="checkbox"
                              />
                              <b>
                                <span style="color:red">*</span>
                              </b>{" "}
                              I do hereby declare that the details mentioned in
                              my resume are true and correct to the best of my
                              knowledge and belief. I bear the responsibilities
                              for the correctness of the above mentioned
                              particulars
                            </label>
                          </div>
                          <div className="col-md-12 pt-4">
                            <div className="btn_box btn_submit_student">
                              <button
                                type="submit"
                                id="btnFSave"
                                className="btn btn-danger start_course2 btn_student_form"
                                name="btnFSave"
                                value="SubmitApplyNow"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                        <input
                          name="__RequestVerificationToken"
                          type="hidden"
                          value="CfDJ8BVxI83FuV5AgcsT6gKjl-nzdXNlmQkGPF1HquO7Mz6YyLvKzwVKyrxCLenUldIrp_ymm3jEu2-44Y585av-Ry_eUA-UnfQ8DmuHL8ElF-HpOUZ-ulouZZFHkL28s3bMlTecwiXzqcGonXAf9YNNvCo"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-12 pt-6">
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Becomeaninstructor;
