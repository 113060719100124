import React from "react";
import { Link } from "react-router-dom";
import datasn from "../course.json";
export const AnimationCourse = () => {
  return (
    <div className="rbt-course-area bg-color-extra2 rbt-section-gap overflow-hidden">
      <div className="container">
        <div className="row mb--60">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle bg-secondary-opacity">
                Popular Course
              </span>
              <h2 className="title">ANIMATION</h2>
            </div>
          </div>
        </div>
        {/* Start Card Area */}
        <div className="row g-5">
          {datasn.courseganimation.map((element) => (
            <div className="col-md-4">
              <div className="single-column-20 swiper-slide">
                <div className="single-slide">
                  <div className="rbt-card variation-01 rbt-hover">
                    <div className="rbt-card-img">
                      <Link to={element.linkee}>
                        <img src={element.imgg} alt={element.namee} />
                      </Link>
                    </div>
                    <div className="rbt-card-body">
                      <span className="designation theme-gradient">
                        {element.courseee}
                      </span>
                      <h4 className="rbt-card-title">
                        <Link to={element.linkee}>{element.namee}</Link>
                      </h4>
                      <ul className="rbt-meta">
                        <li>
                          <i className="feather-clock" />
                          Duration: {element.durati}
                        </li>
                        <li>
                          <i className="feather-book" />
                          <b>Fees: {element.pricee}</b>
                        </li>
                        <li>
                          <i className="feather-star" />
                          Rating:
                          <span className="rating">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star-half" />
                          </span>
                        </li>
                        <li>
                          <i className="feather-users" />
                          Efforts: {element.effortse}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>{" "}
      </div>{" "}
    </div>
  );
};
export default AnimationCourse;
