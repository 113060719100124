import React from 'react'

const Leadership = () => {
  return (
    <>
    <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gapTop mt--30">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="thumbnail-wrapper">
                <img src="assets/images/guruji.png" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="inner pl--50 pl_sm--0 pl_md--0">
                <div className="section-title text-start">
                  {/* <span className="subtitle bg-coral-opacity">
                    Know About Us
                  </span> */}
                  <h4 className="title ">First Chancellor&#x2019;s Message</h4>
                </div>
                <p> &quot;The economy of any country and the wealth of any individual depend on the effectiveness of business management and governmental administration.&quot;</p>
                <p className="description mt--30" align="justify">
                The creativity of the Indian mind is renowned around the world. Indian leadership in business management and public administration will succeed in building a stable, wealthy national economy as a model for the rest of the globe with the help of competent business training.He developed the straightforward, organic Transcendental Meditation technique, which enables anyone to access and experience the field of pure awareness, the wellspring of all knowledge and creativity.&nbsp;This effortless and systematic technique had been lost to human life until Maharishi, inspired by his own teacher, brought it back to us.
                </p>
                <div className="section-title text-start">
                  {/* <span className="subtitle bg-coral-opacity">
                    Know About Us
                  </span> */}
                  <h3 className="title">Maharishi Mahesh Yogi</h3>
                  <p>1918-2008</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End About Area  --> */}

      {/* <!-- Start  Area  --> */}
      <div className="rbt-video-area rbt-section-gapBottom pt--50 bg-color-extra2 mt--30">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="inner pr--50">
                <div className="section-title text-start">
                  {/* <span className="subtitle bg-primary-opacity">
                    How We Work
                  </span> */}
                    <h4 className="title ">Chancellor&#x2019;s Message</h4>
                    </div>

                  <p className="description mt--30" align="justify">
                  I cordially invite you to join MUIT and embark on an exciting journey of continuous learning, creativity, and innovation. Life at MUIT unleashes each individual's full creative potential through the scientifically validated technique of Transcendental Meditation, with the goal of producing holistic, stress-free professionals capable of synthesising conventional and modern thinking with a global outlook and moving ahead of time. MUIT encourages students to stay connected to the world and informed about the latest advancements in their fields.
                  </p>
                  <p className="description mt--30" align="justify">
                    {" "}
                    We are proud to have helped so many students from across the country advance into rewarding careers of their choice.
Our dedicated faculty and dynamic students are highly valued members of our community, demonstrating dedication in both academics and other campus activities.
                  </p>
                
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="video-popup-wrapper">
                <img
                  className="w-100 rbt-radius"
                  src="assets/images/chancellorsir.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End  Area  --> */}

      {/* <!-- Start  Area  --> */}
      {/* <div className="rbt-video-area rbt-section-gapBottom pt--50">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 order-2 order-lg-1">
              <div className="inner pr--50">
                <div className="section-title text-start">
                  <p className="description mt--30" align="justify">
                    The campus is a living example of harmonious
                    multiculturalism with students from all the states, union
                    territories and different countries. A promoter of sports,
                    music and literary activities, it is a nurturing ground for
                    creative excellence. The vibrant culture of the University
                    has embraced innovation, and its entrepreneurial perspective
                    encourages students, staff and faculty to challenge
                    convention, lead discovery and explore new ways of learning.
                  </p>
                  <p className="description mt--30" align="justify">
                    Discover a comprehensive spectrum of industry-oriented
                    courses designed to equip each student with skills and
                    knowledge to respond effectively in a rapidly changing
                    business environment only at Maharishi University of
                    Information Technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- End  Area  --> */}
      <div className="rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0"></hr>
        </div>
      </div>
    </>
  )
}

export default Leadership