import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { Button } from "reactstrap";

class Popup extends React.Component {
  
  constructor(props) {
    super(props);
    this.addFormData = this.addFormData.bind(this);
   
  }
  

  //Form Submission
  addFormData(evt) {
    evt.preventDefault();
    const fd = new FormData();
    if (this.refs.LFirstname.value !== "")
      fd.append("LFirstname", this.refs.LFirstname.value);
    if (this.refs.LEmail.value !== "")
      fd.append("LEmail", this.refs.LEmail.value);
    if (this.refs.LMobile.value !== "")
      fd.append("LMobile", this.refs.LMobile.value);
    if (this.refs.CourseId.value !== "")
      fd.append("CourseId", this.refs.CourseId.value);
    if (this.refs.LLastname.value !== "")
      fd.append("LLastname", this.refs.LLastname.value);
      if (this.refs.LLeadsfrom.value !== "")
      fd.append("LLeadsfrom", this.refs.LLeadsfrom.value);

    if (
      this.refs.LFirstname.value === "" ||
      this.refs.LEmail.value === "" ||
      this.refs.LMobile.value === "" ||
      this.refs.CourseId.value === "" ||
      this.refs.LLastname.value === ""||
      this.refs.LLeadsfrom.value === ""
    ) {
      swal({
        title: "Please fill all entries!",
        //text: 'res.data.data',
        text: "Fill Entries",
        icon: "error",
      });
    } else {
      axios
        .post("http://muit.feemanagementsoftware.co.in/php/muitonline.php", fd)
        .then((response) => {
          console.log(response);
          //Success alert
          swal({
            title: "Thank you for getting in touch!",
            //text: 'res.data.data',
            text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon ! Have a great day!",
            icon: "success",
            
          });
          this.myFormRef.reset();
        }
      );
    }
  }
  render() {
    return (
      <>
        <a href="#" data-toggle="modal" data-target="#myModal" className="rbt-btn btn-border icon-hover w-100 d-block text-center">
          Download Brochure
        </a>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header1">
                {/* <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button> */}
                <h3 className="title-form1">Get a Free Course Brochure</h3>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={this.addFormData}
                  
                  ref={(el) => (this.myFormRef = el)}
                  id="contact-form"
                  method="POST"
                  className="rainbow-dynamic-form max-width-auto"
                >
                  <div className="form-group">
                    <input
                      name="myName"
                      id="LFirstname"
                      ref="LFirstname"
                      type="text"
                    />
                    <label>First Name</label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="form-group">
                    <input
                      name="myMessage"
                      id="LLastname"
                      ref="LLastname"
                      type="text"
                    />
                    <label>Last Name</label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="form-group">
                    <input
                      name="myEmail"
                      id="LEmail"
                      ref="LEmail"
                      type="email"
                    />
                    <label>Email</label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="form-group">
                    <input
                      type="Hidden"
                      id="CourseId"
                      ref="CourseId"
                      name="myPrograms"
                      value="6"
                    />
                    {/* <label>Subject</label>
                    <span className="focus-border"></span> */}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      id="LMobile"
                      ref="LMobile"
                      name="myPhone"
                    />
                    <label>Mobile Number</label>
                    <span className="focus-border"></span>
                  </div>
                  <div >
                    <input
                      type="Hidden"
                      id="LLeadsfrom"
                      ref="LLeadsfrom"
                      name="myPhone"
                      value="Website"
                    />
                     {/* <label>LLeadsfrom</label>
                    <span className="focus-border"></span> */}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn-download-cr"
                      onClick={this.addFormData}
                      onClick={onButtonClick}
                    >
                      Download                      
                    </button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-close-form"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const onButtonClick = () => {
  fetch("Yoga.pdf").then((response) => {
    response.blob().then((blob) => {
      const fileURL =
      window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "Yoga.pdf";
      alink.click();
  });
});
}
export default Popup;
