import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import Dietoverview from './Dietoverview'
const Dietbanner = () => {

    useEffect(() => {
        document.title = "Diet & Nutrition (Dietician) Course Online | Maharishi University Online";
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute(
          "content",
          "Learn everything you need to know about Diet & Nutrition with Maharishi University Online's comprehensive online courses. Earn your online certificate and join the field today! Enroll today!"
        );
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (keywordsMeta) {
          keywordsMeta.setAttribute(
            "content",
            "Diet & Nutrition Course, Diet & Nutrition Course Online, Nutritionist Course Online, Online Nutritionist Certification Online, Dietician Course Online, Dietician Online Course, best Diet & Nutrition Course, Online Courses for Dietician,"
          );
        } else {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = 'Diet & Nutrition Course, Diet & Nutrition Course Online, Nutritionist Course Online, Online Nutritionist Certification Online, Dietician Course Online, Dietician Online Course, best Diet & Nutrition Course, Online Courses for Dietician,';
          document.head.appendChild(keywordsMeta);
        }
      }, []);

  return (
    <>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
    </Helmet>
    <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active">Diet & Nutrition Certification Course</li>
                        </ul>
                        <h2 className="title">Diet & Nutrition Course </h2>
                        <p className="description">Discover a healthier you with our Diet & Nutrition Course. Uncover Ayurveda insights, nutrition essentials, and meal planning. Explore metabolism, organ systems, and food safety. Embrace a holistic approach for well-being. Transform your health journey—enroll now!</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="online learning platform"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.4</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">12,475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>11,029 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/Shivani.jpeg" alt=""/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                 By <a href="#">Shivani</a> In  <a href="#">Yoga/Health </a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 12/2023</li>
                            <li><i className="feather-globe"></i>English</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dietoverview/>
    </>
  )
}

export default Dietbanner