import React from "react";
//Include Sweetalert
import swal from "sweetalert";
//axios for api request
import axios from "axios";

class Dummypop extends React.Component {
  constructor(props) {
    super(props);
    this.addFormData = this.addFormData.bind(this);
  }

  //Form Submission
  addFormData(evt) {
    evt.preventDefault();
    const fd = new FormData();
    if (this.refs.myName.value !== "")
      fd.append("myName", this.refs.myName.value);
    if (this.refs.myEmail.value !== "")
      fd.append("myEmail", this.refs.myEmail.value);
    if (this.refs.myPhone.value !== "")
      fd.append("myPhone", this.refs.myPhone.value);
    if (this.refs.myPrograms.value !== "")
      fd.append("myPrograms", this.refs.myPrograms.value);
    if (this.refs.myMessage.value !== "")
      fd.append("myMessage", this.refs.myMessage.value);

    if (
      this.refs.myName.value === "" ||
      this.refs.myEmail.value === "" ||
      this.refs.myPhone.value === "" ||
      this.refs.myPrograms.value === "" ||
      this.refs.myMessage.value === ""
    ) {
      swal({
        title: "Please fill all entries!",
        //text: 'res.data.data',
        text: "Fill Entries",
        icon: "error",
      });
    } else {
      axios
        .post("http://muit.feemanagementsoftware.co.in/php/internship.php", fd)
        .then((res) => {
          //Success alert
          swal({
            title: "Thank you for getting in touch!",
            //text: 'res.data.data',
            text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon ! Have a great day!",
            icon: "success",
          });
          this.myFormRef.reset();
        });
    }
  }

  render() {
    return (
      <div className="container">
        <form
          onSubmit={this.addFormData}
          className="row g-3 form-contact comment_form"
          ref={(el) => (this.myFormRef = el)}
        >
          <div className="col-md-12">
            <label htmlFor="inputName4" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="inputName4"
              ref="myName"
              required
            />
          </div>
          <div className="col-12">
            <label htmlFor="inputmyPhone" className="form-label">
              Phone
            </label>
            <input
              type="text"
              className="form-control"
              id="inputmyPhone"
              placeholder="Phone Number"
              ref="myPhone"
              required
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="inputEmail4" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              ref="myEmail"
              required
            />
          </div>

          <div className="col-md-12 pb-1">
            <label htmlFor="inputmyPrograms" className="form-label">
              Internship Programs
            </label>
            <select
              id="inputmyPrograms"
              className="form-select"
              ref="myPrograms"
            >
              <option value="">Choose...</option>
              <option value="Software Developer Internship">
                Software Developer Internship
              </option>
              <option value="Web Designer Internship">
                Web Designer Internship
              </option>
              <option value="Digital Marketing Internship">
                Digital Marketing Internship
              </option>
              <option value="Python Internship">Python Internship</option>
              <option value="Full-Stack Developers Internship">
                Full-Stack Developers Internship
              </option>
            </select>
          </div>
          <div className="col-md-12 mb-2">
            <label htmlFor="inputmyMessage" className="form-label">
              Message
            </label>
            <textarea
              id="inputmyMessage"
              placeholder="Enter message"
              ref="myMessage"
              className="form-control"
              required
            ></textarea>
          </div>

          <div className="col-12 pt-5">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.addFormData}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Dummypop;
