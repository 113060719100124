import React, { useState } from "react";

function Homestudenttestemonial() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <div className="rbt-service-area bg-color-white rbt-section-gapBottom">
        <div className="container">
          <div className="row row--15 mt_dec--30">
            <div className="row  rbt-section-gapTop rbt-banner-image">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="title pt-20">
                    Our Most Satisfied Students Says about us!
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20 min-h1 ">
                <div className="rbt-testimonial-box style-2 " >
                  <div className="inner ">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-01.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Sonu Singh</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        I recently completed the Digital Marketing Training at
                        Maharishi University Online, and I must say it was an
                        exceptional experience. The course content was
                        comprehensive, covering various aspects of digital
                        marketing, from SEO to social media marketing.
                      </p>{" "}
                      {isShowMore && (
                        <p style={{ fontSize: "14px" }}>
                          {" "}
                          The instructors were knowledgeable and supportive,
                          providing practical insights and real-world examples.
                          I also appreciated the friendly and encouraging
                          learning environment, which made the entire journey
                          enjoyable. Overall, I highly recommend Maharishi
                          University Online for anyone looking to excel in the
                          field of digital marketing.
                        </p>
                      )}
                      <a onClick={toggleReadMoreLess}
                      className="read-more-m">
                        {isShowMore ? "Read Less" : "Read More"}
                      </a>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20 ">
                <div className="rbt-testimonial-box style-2 ">
                  <div className="inner">
                    <div className="description">
                      <p style={{ fontSize: "14px" }}>
                      <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-02.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Amrita Singh</h5>
                        </div>
                      </div>
                        Maharishi University Online is the best Online training
                        Program in Noida. I am a student of Animation. Tejaswita
                        Ma'am she are very good and helpful teachers. I am very
                        impressed with mam's teaching skills, with mam's
                        guidance, now I have started seeing my aim clearly.
                      </p>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-03.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Mayank</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        I have a great experience in MUITONLINE Social Media
                        Course, @Ritesh Bhanu sir was my teacher and his
                        teaching skills are very good, he give every student an
                        equal chance to clear their doubts and speak in the
                        class. Also he explain each and everything very clearly.
                        MUITONLINE courses are very budget friendly so anyone
                        can afford it. Thank you so much #MUITONLINE.
                      </p>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-04.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Meenal Kulkarni</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        I have great experience regarding Social media marketing
                        course at MUITONLINE Raju Sir very good and supportive
                        teacher.
                      </p>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-05.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Rajeev</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        MUIT ONLINE has given me the confidence to pursue my
                        dream career in animation certification program, and was
                        the best decision I made. Mayank Sir guided me in the
                        best way possible. The program's affordability, coupled
                        with the quality of content, made it a fantastic value.
                        I now have a certification that's opened doors to new
                        opportunities in my field 3d Animation.
                      </p>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-01.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Harsh</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        Best Education Courses here that helps me lot to build
                        my career and I am very so much happy about MUIT Online
                        really best education place for us we can build our
                        career very well by complete courses here and enjoy,
                        Thanks
                      </p>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-07.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Disha</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        I recently completed the Digital Marketing Training at
                        Maharishi University Online, and I must say it was an
                        exceptional experience. The course content was
                        comprehensive, covering various aspects of digital
                        marketing, from SEO to social media marketing.</p>
                        {isShowMore && (
                        <p style={{ fontSize: "14px" }}>
                             The
                        instructors were knowledgeable and supportive, providing
                        practical insights and real-world examples. I also
                        appreciated the friendly and encouraging learning
                        environment, which made the entire journey enjoyable.
                        Overall, I highly recommend Maharishi University Online
                        for anyone looking to excel in the field of digital
                        marketing.
                      </p>)}

                     

                      <a onClick={toggleReadMoreLess}
                      className="read-more-m">
                        {isShowMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-08.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Amit Singh</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        Maharishi University Online is the best Online training
                        Program in Noida. I am a student of Animation. Tejaswita
                        Ma'am she are very good and helpful teachers. I am very
                        impressed with mam's teaching skills, with mam's
                        guidance, now I have started seeing my aim clearly.
                      </p>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="single-column-20">
                <div className="rbt-testimonial-box style-2">
                  <div className="inner">
                    <div className="description">
                    <div className="clint-info-wrapper">
                        <div className="thumb">
                          <img
                            src="assets/images/testimonial/client-06.png"
                            alt="Clint Images"
                          />
                        </div>
                        <div className="client-info">
                          <h5 className="title">Saurabh Rajput</h5>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        I have a great experience in MUITONLINE Social Media
                        Course, @Ritesh Bhanu sir was my teacher and his
                        teaching skills are very good, he give every student an
                        equal chance to clear their doubts and speak in the
                        class. Also he explain each and everything very clearly.
                        MUITONLINE courses are very budget friendly so anyone
                        can afford it. Thank you so much #MUITONLINE.
                      </p>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homestudenttestemonial;
