import React from "react";

const VissionMission = () => {
  return (
    <>
      <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gapTop mt--30">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="thumbnail-wrapper">
                <img src="assets/images/vission.jpeg" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="inner pl--50 pl_sm--0 pl_md--0">
                <div className="section-title text-start">
                  {/* <span className="subtitle bg-coral-opacity">
                    Know About Us
                  </span> */}
                  <h4 className="title">Vision</h4>
                </div>
                <p className="description mt--30" align="justify">
                  The vision of MUIT is to transform the potentiality of each
                  student into actuality and the full unfoldment of their mental
                  potential through Creative Intelligence. Enhancing the
                  employability and entrepreneurship potential is the thrust
                  focus in the vision of MUIT.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End About Area  --> */}

      {/* <!-- Start  Area  --> */}
      <div className="rbt-video-area rbt-section-gapBottom pt--50 bg-color-extra2 mt--30">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="inner pr--50">
                <div className="section-title text-start">
                  {/* <span className="subtitle bg-primary-opacity">
                    How We Work
                  </span> */}
                  <h4 className="title">Mission</h4>
                </div>
                <p className="description mt--30" align="justify">
                  The Mission of the MUIT is to facilitate the process of
                  education and training to let each student grow into a
                  complete individual who is physically enduring, emotionally
                  mature, intellectually enlightened, aesthetically developed,
                  morally sound and spiritually inclined.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="video-popup-wrapper">
                <img
                  className="w-100 rbt-radius"
                  src="assets/images/mission.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="students-stories-area ptb-50 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-red">Aims</h3>
              <div className="list">
                <p>
                  <ul>
                    <li>
                      Imparting the knowledge of specific discipline in
                      interdisciplinary mode in all related fields to contribute
                      in the development of the society.
                    </li>
                    <li>
                      Performing all the activities and related functions in the
                      University that will lead to create the entrepreneurs and
                      leaders in the respective disciplines.
                    </li>
                    <li>
                      Cultivating the environment of intellectual enlightenment
                      among students and faculty members.
                    </li>
                    <li>
                      Developing the physical endurance among students and
                      faculty members.
                    </li>
                    <li>
                      Aesthetically developing the world className talent
                      thereby developing the culture of diversity
                    </li>
                    <li>
                      Creating a Stress-free, collaborative and ethical
                      environment where research, innovation and creativity can
                      flourish.
                    </li>
                    <li>
                      Developing emotional maturity, spirituality and character
                      among students and faculty members through life skills
                      programming which will be useful for self, family and
                      society.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End  Area  --> */}

      {/* <!-- Start  Area  --> */}

      {/* <!-- End  Area  --> */}
    </>
  );
};

export default VissionMission;
