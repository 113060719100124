import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Abaout = () => {
  useEffect(() => {
    document.title = "Maharishi University Online (MUIT Online) | Best Online Course";
    let descMeta = document.querySelector("meta[name='description']");
    descMeta.setAttribute(
      "content",
      "Maharishi University Online (MUIT Online) provides various online courses, including Digital Marketing Course Online, 3D Animation, Entrepreneurship, Diet and Nutrition, and Yoda Certification. It offers flexible, accessible education tailored to modern learning requirements, allowing students to gain skills in various fields at their own pace."
    );
    let keywordsMeta = document.querySelector("meta[name='keywords']");
    if (keywordsMeta) {
      keywordsMeta.setAttribute(
        "content",
        "Maharishi University Online, MUIT Online, MUIT Online Certifications, MUIT Online Courses, MUIT Online University, MUIT Online Noida"
      );
    } else {
      keywordsMeta = document.createElement('meta');
      keywordsMeta.name = 'keywords';
      keywordsMeta.content = 'Maharishi University Online, MUIT Online, MUIT Online Certifications, MUIT Online Courses, MUIT Online University, MUIT Online Noida';
      document.head.appendChild(keywordsMeta);
    }
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
      </Helmet>

      <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gapTop mt--30">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="thumbnail-wrapper">
                <img src="assets/images/abt.jpeg" alt="About Us" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="inner pl--50 pl_sm--0 pl_md--0">
                <div className="section-title text-start">
                  <span className="subtitle bg-coral-opacity">KNOW ABOUT US</span>
                  <h2 className="title">Maharishi University Online</h2>
                </div>
                <p className="description mt--30" align="justify">
                  Welcome to MUIT Online University, your premier destination for innovative and flexible online education. Our platform is designed to offer a diverse range of courses that cater to the evolving needs of today’s learners and professionals. Maharishi University Online (MUIT Online) is one of the best university and online platforms that provides job assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-video-area rbt-section-gapBottom pt--50 bg-color-extra2 mt--30">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="inner pr--50">
                <div className="section-title text-start">
                  <span className="subtitle bg-primary-opacity">OUR Vision</span>
                  <p className="description mt--30" align="justify">
                    At MUIT Online University, our vision is to make high-quality education accessible to everyone, everywhere. We aim to provide flexible, innovative online courses that help students develop the skills they need to succeed in today’s world. We want to create a learning environment where students can easily fit their studies into their lives, learn from experienced instructors, and connect with a supportive community. Our goal is to help each student reach their full potential and achieve their personal and professional dreams.
                  </p>
                </div>
                <div className="section-title text-start">
                  <span className="subtitle bg-primary-opacity">OUR COMMITMENT TO EXCELLENCE</span>
                  <p className="description mt--30" align="justify">
                    MUIT Online is located in Noida. MUIT Online University provides flexible, self-paced learning opportunities that can be accessed from anywhere in the world. Because it’s an Online certification Course, it provides the Best Online Courses like Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="video-popup-wrapper">
                <img className="w-100 rbt-radius" src="assets/images/abt2.jpg" alt="Vision and Commitment" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-video-area rbt-section-gapBottom pt--50">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 order-2 order-lg-1">
              <div className="inner pr--50"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0"></hr>
        </div>
      </div>
    </>
  );
};

export default Abaout;
