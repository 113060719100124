import React from "react";
import axios from "axios";
import swal from "sweetalert";
class Poups extends React.Component {
  constructor(props) {
    super(props);
    this.addFormData = this.addFormData.bind(this);
  }

  //Form Submission
  addFormData(evt) {
    evt.preventDefault();
    const fd = new FormData();
    if (this.target.LFirstname.value !== "")
      fd.append("LFirstname", this.target.LFirstname.value);
    if (this.target.LEmail.value !== "")
      fd.append("LEmail", this.target.LEmail.value);
    if (this.target.myPrograms.value !== "")
      fd.append("CourseId", this.target.myPrograms.value);
    if (this.target.LMobile.value !== "")
      fd.append("LMobile", this.target.LMobile.value);
    if (this.target.LLastname.value !== "")
      fd.append("LLastname", this.target.LLastname.value);
    if (this.target.LLeadsfrom.value !== "")
      fd.append("LLeadsfrom", this.target.LLeadsfrom.value);
    if (this.target.LAction.value !== "")
      fd.append("LAction", this.target.LAction.value);

    if (
      this.target.LFirstname.value === "" ||
      this.target.LEmail.value === "" ||
      this.target.LMobile.value === "" ||
      this.target.CourseId.value === "" ||
      this.target.LLastname.value === ""||
      this.target.LLeadsfrom.value === "" ||
      this.target.LAction.value === ""
    ) {
      swal({
        title: "Please fill all entries!",
        //text: 'res.data.data',
        text: "Fill Entries",
        icon: "error",
      });
    } else {
      axios
        .post("https://mocrm.muituniversity.in/api/EduoApi/BrocAddLead", fd)
        .then((response) => {
          console.log(response);
          //Success alert 
          swal({
            title: "Thank you for getting in touch!",
            //text: 'res.data.data',
            text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon ! Have a great day!",
            icon: "success",
          });
          this.myFormRef.reset();
        })
          .catch((error)=>{
            console.log(error);
          });
    }
  }
  render() {
    return (
      <>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header1">
                <h3 className="title-form1">Book Seat For Free Counselling</h3>
                <p className="title-form1">
                  Speak To Our Specialist - +91 - 9599071023
                </p>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={this.addFormData}
                  ref={(el) => (this.myFormRef = el)}
                  id="contact-form"
                  method="POST"
                  className="rainbow-dynamic-form max-width-auto"
                >
                  <div className="form-group">
                    <input
                      name="LFirstname"
                      id="LFirstname"
                      ref="LFirstname"
                      type="text"
                      required=""
                      
                    />
                    <label>First Name</label>
                    <span className="focus-border"></span>
                  </div>
                  {/*  */}
                  <div className="form-group">
                    <input
                      name="LLastname"
                      id="LLastname"
                      ref="LLastname"
                      type="text"
                      required=""
                     
                    />
                    <label>Last Name</label>
                    <span className="focus-border"></span>
                  </div>
                  {/*  */}
                  <div className="form-group">
                    <input
                      name="LEmail"
                      id="LEmail"
                      ref="LEmail"
                      type="email"
                      required=""
                      
                    />
                    <label>Email</label>
                    <span className="focus-border"></span>
                  </div>
                  {/*  */}
                  <div className="form-group">
                    <input
                      type="text"
                      id="LMobile"
                      ref="LMobile"
                      name="LMobile"
                      required=""
                     
                    />
                    <label>Mobile Number</label>
                    <span className="focus-border"></span>
                  </div>
                  {/*  */}
                  <div className="form-group">
                    <select
                      classNmae="form-control"
                      name="CourseId"
                      required=""
                      id="CourseId"
                      className="form-select"
                      ref="CourseId"
                    >
                      <option selected value="">
                        Select Programs & Certification
                      </option>
                      <option value="1">Fundamental Of 3D Animation</option>
                      <option value="4">
                        Visual Development/Concept Art Advanced
                      </option>
                      <option value="5">
                        Motion Design for Graphic Designers
                      </option>
                      <option value="6">Architecture Visualization</option>
                      <option value="7">Digital Marketing Advance</option>
                      <option value="8">
                        Social Media Marketing Short-Term Course{" "}
                      </option>
                      <option value="9">Digital Marketing Basic</option>
                      <option value="11">
                        Entrepreneurship Certification Course
                      </option>
                      <option value="12">Certification Course in Yoga</option>
                      <option value="13">
                        Certification Course in Diet and Nutrition
                      </option>
                      <option value="14">
                        Certification Course in Videography
                      </option>
                      <option value="15">Ai in Digital Marketing</option>
                      <option value="16">WordPress</option>
                    </select>
                  </div>
                  {/*  */}
                  <div>
                    <input
                      type="Hidden"
                      id="LLeadsfrom"
                      ref="LLeadsfrom"
                      name="LLeadsfrom"
                      value="Website"
                    />
                  </div>
                  {/*  */}
                  <div>
                    <input
                      type="Hidden"
                      id="LAction"
                      ref="LAction"
                      name="LAction"
                      value="Pending"
                    />
                  </div>
                  {/*  */}
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn-download-cr"
                      onClick={this.addFormData}
                    >
                      submit
                    </button>
                  </div>
                  {/*  */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Poups;
