import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import resumeData from "../resumeData.js";
import VerticalTab from "./VerticalTab";

const Navbar = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const closeBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    <>
      <div className="social_fixed">
        <ul>
          <li className="call_ic">
            <a href="tel:9599071023">
              <i className="fa fa-phone text-white"></i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/9599071023" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-whatsapp text-white"></i>
            </a>
          </li>
          <li>
            <Link to="/Contactus">
              <i className="fa fa-envelope text-white"></i>
            </Link>
          </li>
        </ul>
      </div>
      
      {isBannerVisible && (
        <div className="refer-rewards-banner floating course_page active">
          <button className="close-btn" onClick={closeBanner}>X</button>
          <a
            href="https://api.whatsapp.com/send?phone=919599071023&text=Hi, I would like to refer some students" target="blank"
            className="track-click-mp refer-rewards-link js-common-cta"
            data-title=""
            data-event-type="refer_and_earn_floating_banner"
            data-gl-target="corp-login-modal"
          ></a>
          <div className="wrapper">
            <div className="text">
              <h3>
                Refer &amp; Win <span>&gt;</span>
              </h3>
              <p>Premium course worth ₹15,000/-</p>
            </div>
            <div className="gift-box-wrapper">
              <div className="gift-box" style={{ animationPlayState: "running" }}>
                <div className="top"></div>
                <div className="bottom"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <header className="rbt-header rbt-header-10">
        <div className="rbt-sticky-placeholder"></div>

        <div className="rbt-header-wrapper header-space-betwween header-sticky">
          <div className="container-fluid">
            <div className="mainbar-row rbt-navigation-center align-items-center">
              <div className="header-left rbt-header-content">
                <div className="header-info">
                  <div className="logo">
                    <a href="/">
                      <img
                        src="assets/images/logo/logo.png"
                        alt="Maharishi University Online"
                      />
                    </a>
                  </div>
                </div>

                <div className="header-info">
                  <div className="rbt-category-menu-wrapper">
                    <div className="rbt-category-btn rbt-side-offcanvas-activation">
                      <div className="rbt-offcanvas-trigger md-size icon">
                        <span className="d-none d-xl-block">
                          <i className="feather-grid"></i>
                        </span>
                        <i
                          title="Category"
                          className="feather-grid d-block d-xl-none"
                        ></i>
                      </div>
                      <span className="category-text d-none d-xl-block">
                        Certificate Programs
                      </span>
                    </div>
                    <div className="category-dropdown-menu d-none d-xl-block">
                      <div className="category-menu-item">
                        <VerticalTab data={resumeData.jobs} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rbt-main-navigation d-none d-xl-block">
                <nav className="mainmenu-nav">
                  <ul className="mainmenu">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <Link to="About">About us</Link>
                    </li>
                    <li>
                      <a
                        href="https://registration.muitonline.com/Certificateverify"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Verify Certificate
                      </a>
                    </li>
                    <li>
                      <Link to="Contactus">Contact us</Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="header-right">
                <ul className="quick-access">
                  <li className="account-access rbt-user-wrapper d-none d-xl-block">
                    <a href="https://stdedu.muitonline.com/" target="_blank" rel="noopener noreferrer">
                      <i className="feather-user"></i>Login
                    </a>
                  </li>
                </ul>

                <div className="rbt-btn-wrapper d-none d-xl-block">
                  <a
                    className="rbt-btn btn-border-gradient radius-round btn-sm hover-transform-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://registration.muitonline.com"
                  >
                    <span>Register Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
